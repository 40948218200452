import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from "jquery";
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import { AppName, Version, ClientName, Api, ExamMonths, PSO } from '../../../variables';
import API from '../../../config/API';
import { setls, getls, getlsItem, upperCase, capitalizeFirst, groupByMultiple, getDescSortOrder, decimalKeyPress } from '../../../config';
import { PageLoader, LineLoader } from '../../../elements';
import axios from 'axios';
import { AutoComplete } from 'primereact/autocomplete';

import Menu from './Menu';
import Chart from 'react-apexcharts';

const tdStyle = { border: '1px solid black', padding: '5px' }

class BestofPso extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			academicList: [],
			degreeList: [],
			yearsList: [],
			courseList: [],
			subjectList: [],
			selectedCourse: '',
			selectedSubject: '',
			selectedDept: getls('user', 'deptid'),
			selectedDegree: '',
			selectedBatch: '',
			viewBtnText: 'View',
			studentsList: [],
			studentsView: [],
			studentSuggestionValue: '',
			studentsSuggestion: [],
			selectedStudent: [],
			partAMax: '',
			partBMax: '',
			partCMax: '',
			studentMark: [],
			saveText: 'Save'
		}
		this.printChart = this.printChart.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDegreeChange = this.handleDegreeChange.bind(this);
		this.handleYearChange = this.handleYearChange.bind(this);
		this.handleViewBtnClick = this.handleViewBtnClick.bind(this);
	}

	printChart() {
		$(".apexcharts-toolbar").css("display", "none");
		$("#printbtn").css("display", "none");
		$(".print_title").css("display", "block");
		var content = document.getElementById("print_chart");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		$(".apexcharts-toolbar").css("display", "block");
		$("#printbtn").css("display", "block");
		$(".print_title").css("display", "none");
		pri.document.close();
		pri.focus();
		pri.print();
	}

	handleSubmit(e) {
		try {
			e.preventDefault();
			this.setState({ showLoader: true, saveText: 'Saving' });
			API.post('v1/savepso', $("#frm_add_pos_mark").serialize()).then(response => {
				if (response['data'].status == '1') {
					this.setState({ showLoader: false, saveText: 'Save', studentsList: [], studentMark: [] });
					document.getElementById('frm_add_pos_mark').reset();
					notify.show(response['data'].message, 'success');
					this.getMarks();
				}
			});
		}
		catch (error) {
			console.log(error);
		}
	}

	getMarks() {
		try {

			var form = new FormData();
			form.append('department', $("#i__deptId").val());
			form.append('course', this.state.selectedCourse);
			form.append('subject', this.state.selectedSubject);
			form.append('semester', $("#i__semester").val());
			form.append('batch', $("#i__batch").val());

			this.setState({ showLoader: true });
			API.post('v1/getpsomark/', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					this.setState({ studentMark: d, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	componentDidMount() {
		this.setState({ courseList: JSON.parse(getlsItem('courselist')) });
		this.loadAcademics();
		this.loadSubject();
	}

	loadAcademics() {
		try {

			this.setState({ showLoader: true });
			axios.get(Api.AcademicPath + '/course/list_current_academics').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					var dl = groupByMultiple(d, function (obj) {
						return [obj.type];
					});
					this.setState({ academicList: d, degreeList: dl, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	loadSubject() {
		try {

			this.setState({ showLoader: true });
			var userid = getls('user', 'id');
			API.get('v1/getpsosubject/' + userid).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					this.setState({ subjectList: d, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	handleDegreeChange(e) {
		try {

			this.setState({ selectedCourse: e.target.value });
			var v = $("#i__course option:selected").data("type");
			var cname = $("#i__course option:selected").data("name");
			var d = this.state.academicList.filter(function (obj) {
				if(v == 'UG'){
					return (obj.type == v && obj.semester == 6);
				}
				else if(v == 'PG'){
					return (obj.type == v && obj.semester == 4);
				}
			});
			d.sort(getDescSortOrder('startyear'));
			this.setState({ yearsList: d, selectedDegree: v, cname: cname });
		}
		catch (error) { }
	}

	handleYearChange(e) {
		try {
			var v = e.target.value;
			var d = this.state.yearsList.filter(function (obj) {
				return (obj.startyear == v);
			});
			this.setState({ selectedBatch: d[0]['value'] });
		}
		catch (error) { }
	}

	handleViewBtnClick() {
		try {

			this.getMarks();
			if (this.state.selectedBatch == '' || this.state.selectedCourse == '' || this.state.selectedDegree == '' || this.state.selectedDept == '' || this.state.selectedSubject == '') {
				notify.show('Please selected [Batch, Course, Degree, Department, Subject]', 'warning');
				return;
			}
			this.setState({ showLoader: true, viewBtnText: '...', studentsList: [], studentsView: [] });
			var form = new FormData();
			form.append('course', this.state.selectedCourse);
			form.append('batch', this.state.selectedBatch);
			form.append('degree', this.state.selectedDegree);
			form.append('deptid', this.state.selectedDept);
			axios.post(Api.AcademicPath + '/students/studentslist_pso', form).then(res => {
				if (res['data'].status == '1') {
					this.setState({ studentsList: res['data'].data, studentsView: res['data'].data });
				}
				else {
					notify.show(res['data'].msg, 'error');
				}
				this.setState({ showLoader: false, viewBtnText: 'View' });
			});
		}
		catch (error) {

		}
	}

	handleMarkChange(regno, type, unit, e) {
		try {
			var d = this.state.studentMark;
			var _scored = e.target.value;
			var _internal = type + '_co' + unit;
			var index = d.findIndex(function (obj) {
				return (obj.registerno == regno);
			});
			if (index > -1) {
				d[index][_internal] = _scored;
			}
			else {
				d.push({ registerno: regno, [_internal]: _scored });
			}
			this.setState({ studentMark: d });
			this.updateTotal();
		}
		catch (error) { }
	}

	getScoredMark(regno, type, unit) {
		try {
			var _internal = type + '_co' + unit;
			var d = this.state.studentMark;
			/*var d = this.state.studentMark.filter(function (obj) {
				return (obj.registerno == regno);
			});*/
			var index = d.findIndex(function (obj) {
				return (obj.registerno == regno);
			});
			if (index > -1) {
				return d[index][_internal];
			}
		}
		catch (error) { }
	}

	getInternalTotal(regno, internal) {
		try {
			var mark = 0;
			var d = this.state.studentMark.filter(function (obj) {
				if (obj.registerno == regno) {
					if (internal == 'quiz') {
						mark = obj.quiz_total;
					}
					if (internal == 'assignment') {
						mark = obj.assignment_total;
					}
				};
			});
			return mark;
		}
		catch (error) { }
	}

	updateTotal() {

		var d = this.state.studentMark;
		d.map(function (obj, i) {
			var mark1 = 0;
			var mark2 = 0;
			var mark3 = 0;
			if ('quiz_co1' in obj) {
				mark1 += parseFloat(obj.quiz_co1);
			}
			if ('quiz_co2' in obj) {
				mark1 += parseFloat(obj.quiz_co2);
			}
			if ('quiz_co3' in obj) {
				mark1 += parseFloat(obj['quiz_co3']);
			}
			if ('quiz_co4' in obj) {
				mark1 += parseFloat(obj['quiz_co4']);
			}
			if ('quiz_co5' in obj) {
				mark1 += parseFloat(obj['quiz_co5']);
			}
			d[i]['quiz_total'] = mark1;

			if ('assignment_co1' in obj) {
				mark2 += parseFloat(obj['assignment_co1']);
			}
			if ('assignment_co2' in obj) {
				mark2 += parseFloat(obj['assignment_co2']);
			}
			if ('assignment_co3' in obj) {
				mark2 += parseFloat(obj['assignment_co3']);
			}
			if ('assignment_co4' in obj) {
				mark2 += parseFloat(obj['assignment_co4']);
			}
			if ('assignment_co5' in obj) {
				mark2 += parseFloat(obj['assignment_co5']);
			}
			d[i]['assignment_total'] = mark2;
		});

		this.setState({ studentMark: d });
	}

	getBestTotal(regno, field) {
		var d = this.state.studentMark;
		var dat = d.filter(function (obj) {
			return (obj.registerno == regno);
		});

		var input = [];
		for (var i = 0; i < dat.length; i++) {
			input.push(dat[i].internal1_total);
			input.push(dat[i].internal2_total);
			input.push(dat[i].internal3_total);
		}

		var output = this.largest(input);
		if (output.length > 0) {
			if (field == 1) {
				return output[0];
			}
			if (field == 2) {
				return output[1];
			}
		}
		else {
			return 0;
		}
	}

	largest(arr) {
		arr.sort();
		return arr.reverse();
	}

	getBestTwo(regno, field) {
		var d = this.state.studentMark;
		var dat = d.filter(function (obj) {
			return (obj.registerno == regno);
		});
		var mark = 0;
		if (field == 1) {
			dat.map((obj) => {
				mark += parseFloat(obj.internal1_co1);
				mark += parseFloat(obj.internal2_co1);
				mark += parseFloat(obj.internal3_co1);
			})
		}
		if (field == 2) {
			dat.map((obj) => {
				mark += parseFloat(obj.internal1_co2);
				mark += parseFloat(obj.internal2_co2);
				mark += parseFloat(obj.internal3_co2);
			})
		}
		if (field == 3) {
			dat.map((obj) => {
				mark += parseFloat(obj.internal1_co3);
				mark += parseFloat(obj.internal2_co3);
				mark += parseFloat(obj.internal3_co3);
			})
		}
		if (field == 4) {
			dat.map((obj) => {
				mark += parseFloat(obj.internal1_co4);
				mark += parseFloat(obj.internal2_co4);
				mark += parseFloat(obj.internal3_co4);
			})
		}
		if (field == 5) {
			dat.map((obj) => {
				mark += parseFloat(obj.internal1_co5);
				mark += parseFloat(obj.internal2_co5);
				mark += parseFloat(obj.internal3_co5);
			})
		}

		return (mark / 2);
	}

	getBestTotalAll(regno) {
		var d = this.state.studentMark;
		var dat = d.filter(function (obj) {
			return (obj.registerno == regno);
		});

		var input = [];
		for (var i = 0; i < dat.length; i++) {
			input.push(dat[i].internal1_total);
			input.push(dat[i].internal2_total);
			input.push(dat[i].internal3_total);
		}

		var output = this.largest(input);
		if (output.length > 0) {
			var best1 = parseFloat(output[0]).toFixed(2);
			var best2 = parseFloat(output[1]).toFixed(2);
			return parseFloat(parseFloat(best1) + parseFloat(best2)).toFixed(2);
		}
		else {
			return 0;
		}
	}

	getSubject(id, field) {
		var d = this.state.subjectList;

		var index = d.findIndex(function (obj) {
			return (obj.id == id);
		});
		if (index > -1) {
			return d[index][field];
		}
	}

	getOverall(regno, field) {
		var d = this.state.studentMark;
		var dat = d.filter(function (obj) {
			return (obj.registerno == regno);
		});
		var mark = 0;
		if (field == 1) {
			dat.map((obj) => {
				mark += parseFloat(obj.internal1_co1);
				mark += parseFloat(obj.internal2_co1);
				mark += parseFloat(obj.internal3_co1);
				mark += parseFloat(obj.assignment_co1);
				mark += parseFloat(obj.quiz_co1);
			})
		}
		if (field == 2) {
			dat.map((obj) => {
				mark += parseFloat(obj.internal1_co2);
				mark += parseFloat(obj.internal2_co2);
				mark += parseFloat(obj.internal3_co2);
				mark += parseFloat(obj.assignment_co2);
				mark += parseFloat(obj.quiz_co2);
			})
		}
		if (field == 3) {
			dat.map((obj) => {
				mark += parseFloat(obj.internal1_co3);
				mark += parseFloat(obj.internal2_co3);
				mark += parseFloat(obj.internal3_co3);
				mark += parseFloat(obj.assignment_co3);
				mark += parseFloat(obj.quiz_co3);
			})
		}
		if (field == 4) {
			dat.map((obj) => {
				mark += parseFloat(obj.internal1_co4);
				mark += parseFloat(obj.internal2_co4);
				mark += parseFloat(obj.internal3_co4);
				mark += parseFloat(obj.assignment_co4);
				mark += parseFloat(obj.quiz_co4);
			})
		}
		if (field == 5) {
			dat.map((obj) => {
				mark += parseFloat(obj.internal1_co5);
				mark += parseFloat(obj.internal2_co5);
				mark += parseFloat(obj.internal3_co5);
				mark += parseFloat(obj.assignment_co5);
				mark += parseFloat(obj.quiz_co5);
			})
		}

		return mark;
	}

	getPsoReport(avg1, avg2, avg3, avg4, avg5) {

		var length = this.state.studentsList.length;
		let v1 = parseFloat((avg1 / length) / 20).toFixed(2);
		let v2 = parseFloat((avg2 / length) / 20).toFixed(2);
		let v3 = parseFloat((avg3 / length) / 20).toFixed(2);
		let v4 = parseFloat((avg4 / length) / 20).toFixed(2);
		let v5 = parseFloat((avg5 / length) / 20).toFixed(2);
		var d = [];

		for (var i = 0; i < 5; i++) {
			d.push(<tr>
				<td style={tdStyle} align="center">Co {i + 1}</td>
				<td style={tdStyle} align="center">{this.getPsoAvgResult(v1, v2, v3, v4, v5, i + 1, '1')}</td>
				<td style={tdStyle} align="center">{this.getPsoAvgResult(v1, v2, v3, v4, v5, i + 1, '2')}</td>
				<td style={tdStyle} align="center">{this.getPsoAvgResult(v1, v2, v3, v4, v5, i + 1, '3')}</td>
				<td style={tdStyle} align="center">{this.getPsoAvgResult(v1, v2, v3, v4, v5, i + 1, '4')}</td>
				<td style={tdStyle} align="center">{this.getPsoAvgResult(v1, v2, v3, v4, v5, i + 1, '5')}</td>
				<td style={tdStyle} align="center">{this.getPsoAvgResult(v1, v2, v3, v4, v5, i + 1, '6')}</td>
				<td style={tdStyle} align="center">{this.getPsoAvgResult(v1, v2, v3, v4, v5, i + 1, '7')}</td>
			</tr>);
		}
		return d
	}

	getPsoAvgResult(v1, v2, v3, v4, v5, co, pso) {

		var sub = this.state.subjectList;
		var id = this.state.selectedSubject;
		var index = sub.findIndex(function (obj) {
			return (obj.id == id);
		});

		var subject = sub[index];
		var result = '';

		if (pso == '1') {
			if (co == '1') {
				var arr = subject['co1_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		else if (pso == '2') {
			if (co == '1') {
				var arr = subject['co1_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '3') {
			if (co == '1') {
				var arr = subject['co1_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '4') {
			if (co == '1') {
				var arr = subject['co1_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '5') {
			if (co == '1') {
				var arr = subject['co1_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '6') {
			if (co == '1') {
				var arr = subject['co1_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '7') {
			if (co == '1') {
				var arr = subject['co1_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			if (co == '4') {
				var arr = subject['co4_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			if (co == '5') {
				var arr = subject['co5_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		return result;
	}

	getPoReport(avg1, avg2, avg3, avg4, avg5) {

		var length = this.state.studentsList.length;
		let v1 = parseFloat((avg1 / length) / 20).toFixed(2);
		let v2 = parseFloat((avg2 / length) / 20).toFixed(2);
		let v3 = parseFloat((avg3 / length) / 20).toFixed(2);
		let v4 = parseFloat((avg4 / length) / 20).toFixed(2);
		let v5 = parseFloat((avg5 / length) / 20).toFixed(2);
		var d = [];

		for (var i = 0; i < 5; i++) {
			d.push(<tr>
				<td style={tdStyle} align="center">Co {i + 1}</td>
				<td style={tdStyle} align="center">{this.getPoAvgResult(v1, v2, v3, v4, v5, i + 1, '1')}</td>
				<td style={tdStyle} align="center">{this.getPoAvgResult(v1, v2, v3, v4, v5, i + 1, '2')}</td>
				<td style={tdStyle} align="center">{this.getPoAvgResult(v1, v2, v3, v4, v5, i + 1, '3')}</td>
				<td style={tdStyle} align="center">{this.getPoAvgResult(v1, v2, v3, v4, v5, i + 1, '4')}</td>
				<td style={tdStyle} align="center">{this.getPoAvgResult(v1, v2, v3, v4, v5, i + 1, '5')}</td>
				<td style={tdStyle} align="center">{this.getPoAvgResult(v1, v2, v3, v4, v5, i + 1, '6')}</td>
				<td style={tdStyle} align="center">{this.getPoAvgResult(v1, v2, v3, v4, v5, i + 1, '7')}</td>
			</tr>);
		}
		return d
	}

	getPoAvgResult(v1, v2, v3, v4, v5, co, pso) {

		var sub = this.state.subjectList;
		var id = this.state.selectedSubject;
		var index = sub.findIndex(function (obj) {
			return (obj.id == id);
		});

		var subject = sub[index];
		var result = '';

		if (pso == '1') {
			if (co == '1') {
				var arr = subject['co1_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		else if (pso == '2') {
			if (co == '1') {
				var arr = subject['co1_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '3') {
			if (co == '1') {
				var arr = subject['co1_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '4') {
			if (co == '1') {
				var arr = subject['co1_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '5') {
			if (co == '1') {
				var arr = subject['co1_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '6') {
			if (co == '1') {
				var arr = subject['co1_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '7') {
			if (co == '1') {
				var arr = subject['co1_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			if (co == '4') {
				var arr = subject['co4_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			if (co == '5') {
				var arr = subject['co5_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		return result;
	}
	
	getAverage(avg1,avg2,avg3,avg4,avg5){
		
		var strength = this.state.studentsList.length;
		avg1 = parseFloat(avg1 / strength);
		avg2 = parseFloat(avg2 / strength);
		avg3 = parseFloat(avg3 / strength);
		avg4 = parseFloat(avg4 / strength);
		avg5 = parseFloat(avg5 / strength);
		
		var avg = parseFloat(avg1+avg2+avg3+avg4+avg5)/5;
		
		return parseFloat(avg).toFixed(2) ;
	}
	
	getAverageAdd(avg1,avg2,avg3,avg4,avg5){
		
		var strength = this.state.studentsList.length;
		avg1 = parseFloat(avg1 / strength)*0.25;
		avg2 = parseFloat(avg2 / strength)*0.25;
		avg3 = parseFloat(avg3 / strength)*0.25;
		avg4 = parseFloat(avg4 / strength)*0.25;
		avg5 = parseFloat(avg5 / strength)*0.25;
		
		var avg = parseFloat(avg1+avg2+avg3+avg4+avg5)/5;
		
		return parseFloat(avg).toFixed(2) ;
	}

	render() {

		let deptName = this.state.courseList.length > 0 ? this.state.courseList[0]['departmentname'] : '';
		let deptId = this.state.courseList.length > 0 ? this.state.courseList[0]['department'] : '';
		let quizass = 0;

		let avg1 = 0;
		let avg2 = 0;
		let avg3 = 0;
		let avg4 = 0;
		let avg5 = 0;
		
		let avgTotal1 = 0;
		let avgTotal2 = 0;
		let avgTotal3 = 0;
		let avgTotal4 = 0;
		let avgTotal5 = 0;
		
		let avg = 0;

		var max = 0;
		var data = [4, 3, 2, 1, 0];

		data.map(d => {
			max = Math.max(max, d)
		})

		var colors = [
			'#008FFB',
			'#00E396',
			'#FEB019',
			'#FF4560',
			'#775DD0'
		]

		let semesterList = [];
		if (this.state.selectedDegree == 'UG') {
			semesterList = [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' },
				{ label: '3', value: '3' },
				{ label: '4', value: '4' },
				{ label: '5', value: '5' },
				{ label: '6', value: '6' }
			]
		}
		else if (this.state.selectedDegree == 'PG') {
			semesterList = [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' },
				{ label: '3', value: '3' },
				{ label: '4', value: '4' }
			]
		}
		else if (this.state.selectedDegree == 'Mphil') {
			semesterList = [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' }
			]
		}

		return (
			<div>

				<LineLoader loading={this.state.showLoader} />

				<Menu />

				<div className="pt-50" >

					<form id="frm_add_pos_mark" action="" method="post" onSubmit={this.handleSubmit}>
						<input type="hidden" name="i__userId" value={getls('user', 'id')} />
						<input type="hidden" name="i__deptId" id="i__deptId" value={deptId} />
						<input type="hidden" name="i__degree" id="i__degree" value={this.state.selectedDegree} />
						<input type="hidden" name="i__subject" id="i__subject" value={this.getSubject(this.state.selectedSubject, 'id')} />
						<input type="hidden" name="studentMark" value={JSON.stringify(this.state.studentMark)} />
						<Row>
							<Col md={12} >
								<Row>
									<Col md={1} ><Label>Department</Label></Col>
									<Col md={2}>
										<Input type="text" size="sm" name="i__deptName" value={deptName} required />
									</Col>
									<Col md={2} >
										<Input type="select" name="i__course" id="i__course" size="sm" required onChange={this.handleDegreeChange} >
											<option value=""> -Course- </option>
											{this.state.courseList.map((item, i) => {
												return <option key={i} value={item.id} data-type={item.type} data-name={item.coursename}> {item.coursename} ({item.coursetype == 'regular' ? 'R' : 'SF'})</option>;
											})}
										</Input>
									</Col>
									<Col md={2}>
										<Input type="select" name="i__subCode" size="sm" required onChange={(e) => { this.setState({ selectedSubject: e.target.value }) }} >
											<option value=""> -Subject- </option>
											{this.state.subjectList.map((item, i) => {
												return <option key={i} value={item.id} > {item.subcode} - {capitalizeFirst(item.subname)} </option>;
											})}
										</Input>
									</Col>
									<Col md={2}>
										<Input type="select" name="i__semester" id="i__semester" size="sm" required>
											<option value=""> -Semester- </option>
											{semesterList.map((item, i) => {
												return <option key={i} value={item.value} > {item.label} </option>;
											})}
										</Input>
									</Col>
									<Col md={2}>
										<Input type="select" name="i__batch" size="sm" id="i__batch" onChange={this.handleYearChange} required >
											<option value=""> -Year- </option>
											{this.state.yearsList.map((item, i) => {
												return <option key={i} value={item.startyear} > {item.startyear} </option>;
											})
											}
										</Input>
									</Col>
									<Col md={1}>
										<div className="text-right" >
											<Button type="button" size="sm" color="info" onClick={this.handleViewBtnClick} disabled={this.state.viewBtnText != 'View'} >
												{this.state.viewBtnText != 'View' ? <i className="ion-loading-a"></i> : null}
												&nbsp;{this.state.viewBtnText}
											</Button>
											<button id="printbtn" type="button" style={{ position: 'fixed', bottom: '50px', right: '33px', zIndex: '999999' }} className="btn btn-warning btn-sm" onClick={this.printChart.bind(this)}>
												<i className="ion-printer"></i>
											</button>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>

						{Object.keys(this.state.studentsList).length > 0 ?
							<React.Fragment>
								<Row className="mt-10" >
									<Col md={12} >
										<Card>
											<CardBody>
												<Table className="tbl" style={{ fontSize: '11px' }}>
													<tbody>
														<tr style={{ background: '#00adff' }} >
															<td width="30" rowSpan={2}>S.No</td>
															<td rowSpan={2}>Reg.No</td>
															<td rowSpan={2}>Student Name</td>
															{/*<td rowSpan={2}>Best 1</td>
														<td rowSpan={2}>Best 2</td>
														<td align="center">Best Two AVG (15)</td>*/}
															<td align="center" colSpan="6">Quiz/Seminar (5)</td>
															<td align="center" colSpan="6">Assignment (5)</td>
															<td align="center" colSpan="5">Total</td>
															<td align="center" colSpan="5">Average</td>
														</tr>
														<tr style={{ background: 'rgb(73, 255, 146)' }}>
															{PSO.UNITS.map((item, i) => {
																return <td align="center" >CO{i + 1}</td>;
															})}
															<td align="center" >Total</td>
															{PSO.UNITS.map((item, i) => {
																return <td align="center" >CO{i + 1}</td>;
															})}
															<td align="center" >Total</td>
															{PSO.UNITS.map((item, i) => {
																return <td align="center" >CO{i + 1}</td>;
															})}
															{PSO.UNITS.map((item, i) => {
																return <td align="center" >CO{i + 1}</td>;
															})}
														</tr>
														{this.state.studentsList.map((item, i) => {
															quizass = parseFloat(this.getInternalTotal(item.registerno, 'quiz')) + parseFloat(this.getInternalTotal(item.registerno, 'assignment'));
															return <tr key={i}>
																<td>{i + 1}</td>
																<td>{item.registerno}</td>
																<td>{item.name}</td>
																{/*<td align="right">{this.getBestTotal(item.registerno, '1')}</td>
															<td align="right">{this.getBestTotal(item.registerno, '2')}</td>
															<td align="right">{(this.getBestTotalAll(item.registerno) / 2) / 2}</td>*/}
																{PSO.UNITS.map((item1, i1) => {
																	return <td align="center" >
																		<Input type="number" style={{ fontSize: '11px' }} size="sm" max="1"
																			onChange={this.handleMarkChange.bind(this, item.registerno, 'quiz', i1 + 1)}
																			value={this.getScoredMark(item.registerno, 'quiz', i1 + 1)} />
																	</td>;
																})}
																<td align="center" >{this.getInternalTotal(item.registerno, 'quiz')}</td>
																{PSO.UNITS.map((item2, i2) => {
																	return <td align="center" >
																		<Input type="number" style={{ fontSize: '11px' }} size="sm" max="1"
																			onChange={this.handleMarkChange.bind(this, item.registerno, 'assignment', i2 + 1)}
																			value={this.getScoredMark(item.registerno, 'assignment', i2 + 1)} />
																	</td>;
																})}
																<td align="right">{this.getInternalTotal(item.registerno, 'assignment')}</td>
																{PSO.UNITS.map((item3, i3) => {
																	return <td align="right" >{this.getOverall(item.registerno, i3 + 1)}</td>;
																})}
																{PSO.UNITS.map((item4, i4) => {
																	if (i4 == 0) {
																		avg1 += parseFloat((this.getOverall(item.registerno, i4 + 1) / 20) * 100);
																		avgTotal1 += parseFloat(((this.getOverall(item.registerno, i4 + 1) / 20) * 100)/10);
																	}
																	if (i4 == 1) {
																		avg2 += parseFloat((this.getOverall(item.registerno, i4 + 1) / 20) * 100);
																		avgTotal2 += parseFloat(((this.getOverall(item.registerno, i4 + 1) / 20) * 100)/10);
																	}
																	if (i4 == 2) {
																		avg3 += parseFloat((this.getOverall(item.registerno, i4 + 1) / 20) * 100);
																		avgTotal3 += parseFloat(((this.getOverall(item.registerno, i4 + 1) / 20) * 100)/10);
																	}
																	if (i4 == 3) {
																		avg4 += parseFloat((this.getOverall(item.registerno, i4 + 1) / 20) * 100);
																		avgTotal4 += parseFloat(((this.getOverall(item.registerno, i4 + 1) / 20) * 100)/10);
																	}
																	if (i4 == 4) {
																		avg5 += parseFloat((this.getOverall(item.registerno, i4 + 1) / 20) * 100);
																		avgTotal5 += parseFloat(((this.getOverall(item.registerno, i4 + 1) / 20) * 100)/10);
																	}
																	this.sstate = {
																		options: {
																			chart: {
																				id: 'apexchart-example'
																			},
																			colors: colors,
																			plotOptions: {
																				bar: {
																					columnWidth: '45%',
																					distributed: true
																				}
																			},
																			dataLabels: {
																				enabled: false
																			},
																			xaxis: {
																				categories: ['Co1', 'Co2', 'Co3', 'Co4', 'Co5'],
																				labels: {
																					style: {
																						colors: colors,
																						fontSize: '14px'
																					}
																				}
																			},
																			yaxis: {
																				allowDecimals: false,
																				tickAmount: 5,
																				max: max + 1
																			}
																		},
																		series: [{
																			name: 'average',
																			data: [parseFloat((avg1 / this.state.studentsList.length) / 20).toFixed(2), parseFloat((avg2 / this.state.studentsList.length) / 20).toFixed(2), parseFloat((avg3 / this.state.studentsList.length) / 20).toFixed(2), parseFloat((avg4 / this.state.studentsList.length) / 20).toFixed(2), parseFloat((avg5 / this.state.studentsList.length) / 20).toFixed(2)]
																		}]
																	}
																	
																	return <td align="right" >{parseFloat((this.getOverall(item.registerno, i4 + 1) / 20) * 100).toFixed(2)}</td>;
																})}
																{/*PSO.UNITS.map((item5, i5) => {
																	return <td align="right" >{parseFloat(((this.getOverall(item.registerno, i5 + 1) / 20) * 100)/10).toFixed(2)}</td>;
																})*/}
															</tr>
														})}
														<tr>
															<td align="right" colSpan="20">Total
																<input type="hidden" name="co1_avg" value={parseFloat(avg1 / this.state.studentsList.length).toFixed(2)} />
																<input type="hidden" name="co2_avg" value={parseFloat(avg2 / this.state.studentsList.length).toFixed(2)} />
																<input type="hidden" name="co3_avg" value={parseFloat(avg3 / this.state.studentsList.length).toFixed(2)} />
																<input type="hidden" name="co4_avg" value={parseFloat(avg4 / this.state.studentsList.length).toFixed(2)} />
																<input type="hidden" name="co5_avg" value={parseFloat(avg5 / this.state.studentsList.length).toFixed(2)} />
															</td>
															<td align="right">{parseFloat(avg1 / this.state.studentsList.length).toFixed(2)}</td>
															<td align="right">{parseFloat(avg2 / this.state.studentsList.length).toFixed(2)}</td>
															<td align="right">{parseFloat(avg3 / this.state.studentsList.length).toFixed(2)}</td>
															<td align="right">{parseFloat(avg4 / this.state.studentsList.length).toFixed(2)}</td>
															<td align="right">{parseFloat(avg5 / this.state.studentsList.length).toFixed(2)}</td>
														</tr>
														<tr>
															<td align="right" colSpan="20">Average</td>
															<td align="right">{parseFloat(avgTotal1 / this.state.studentsList.length).toFixed(2)}</td>
															<td align="right">{parseFloat(avgTotal2 / this.state.studentsList.length).toFixed(2)}</td>
															<td align="right">{parseFloat(avgTotal3 / this.state.studentsList.length).toFixed(2)}</td>
															<td align="right">{parseFloat(avgTotal4 / this.state.studentsList.length).toFixed(2)}</td>
															<td align="right">{parseFloat(avgTotal5 / this.state.studentsList.length).toFixed(2)}</td>
														</tr>
													</tbody>
												</Table>
											</CardBody>
											<CardBody>
												<Row>
													<Col md="11"></Col>
													<Col md={1} >
														<Button type="submit" color="primary" disabled={this.state.saveText != 'Save' ? true : false}>
															{this.state.saveText != 'Save' ? <span className="ion-loading-a" ></span> : ''} {this.state.saveText}
														</Button>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</Row>
								<Row id="print_chart">
									<Col md={6}>
										<div className="print_title" style={{ display: 'none' }}>
											<center>
												<b style={{ fontSize: '20px' }}>{ClientName}</b><br />
												Department : <b>{this.state.cname}-{deptName}</b><br />
												Subject : <b>{this.getSubject(this.state.selectedSubject, 'subcode')} - {this.getSubject(this.state.selectedSubject, 'subname')}</b><br />
												Semester : <b>{$("#i__semester").val()}</b>&emsp;&emsp;Batch : <b>{$("#i__batch").val()}</b>
											</center>
										</div>
										<h3>PSO Report</h3>
										<table width="100%" style={{ borderCollapse: 'collapse' }}>
											<thead>
												<tr>
													<th style={tdStyle}>CO</th>
													<th style={tdStyle}>PSO 1</th>
													<th style={tdStyle}>PSO 2</th>
													<th style={tdStyle}>PSO 3</th>
													<th style={tdStyle}>PSO 4</th>
													<th style={tdStyle}>PSO 5</th>
													<th style={tdStyle}>PSO 6</th>
													<th style={tdStyle}>PSO 7</th>
												</tr>
											</thead>
											<tbody>
												{this.getPsoReport(avg1, avg2, avg3, avg4, avg5)}
											</tbody>
										</table>
									</Col>
									<Col md={6}>
										<h3>PO Report</h3>
										<table width="100%" style={{ borderCollapse: 'collapse' }}>
											<thead>
												<tr>
													<th style={tdStyle}>CO</th>
													<th style={tdStyle}>PO 1</th>
													<th style={tdStyle}>PO 2</th>
													<th style={tdStyle}>PO 3</th>
													<th style={tdStyle}>PO 4</th>
													<th style={tdStyle}>PO 5</th>
													<th style={tdStyle}>PO 6</th>
													<th style={tdStyle}>PO 7</th>
												</tr>
											</thead>
											<tbody>
												{this.getPoReport(avg1, avg2, avg3, avg4, avg5)}
											</tbody>
										</table>
									</Col><br /><br />
									<Col md={6} style={{marginTop : '10px'}}>
										<h3>Internal Evaluation Chart</h3>
										<Chart options={this.sstate.options} series={this.sstate.series} type="bar" width={500} height={300} />
									</Col>
									<iframe name="print_frame" id="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
								</Row>
							</React.Fragment> : null}
					</form>

				</div>

			</div >
		);
	}

}

export default BestofPso;
