import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from "jquery";
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import { AppName, Version, ClientName, Api, ExamMonths, PSO } from '../../../variables';
import API from '../../../config/API';
import { setls, getls, getlsItem, upperCase, capitalizeFirst, groupByMultiple, getDescSortOrder, decimalKeyPress } from '../../../config';
import { PageLoader, LineLoader } from '../../../elements';
import axios from 'axios';
import { AutoComplete } from 'primereact/autocomplete';
import Select from 'react-select';
import Menu from './Menu';

class EditSubject extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			selectedDept: getls('user', 'deptid'),
			saveText: 'Update',
			dataList: [],
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleCoLSelectChange(name, e) {
		this.setState({ [name]: e });
		var d = this.state.dataList;
		
		if (e == null) {
			d[name] = '';
		}
		else {
			d[name] = e.value;
		}
		this.setState({ dataList: d });
	}

	handleSelectChange(name, e) {
		this.setState({ [name]: e });
		var d = this.state.dataList;
		var val = '';
		if (e == null) {
			d[name] = '';
		}
		else {
			e.map((item, i) => {
				val += item.value + ',';
			});

			d[name] = val.replace(/,(?=[^,]*$)/, '');
		}
		this.setState({ dataList: d });
	}

	handlePsoSelectChange(name, e) {
		this.setState({ [name]: e });
		var d = this.state.dataList;
		var val = '';
		if (e == null) {
			d[name] = '';
		}
		else {
			e.map((item, i) => {
				val += item.value + ',';
			});

			d[name] = val.replace(/,(?=[^,]*$)/, '');
		}
		this.setState({ dataList: d });
	}

	handleSubmit(e) {
		try {
			e.preventDefault();
			this.setState({ showLoader: true, saveText: 'Updating' });
			API.post('v1/updatesubject', $("#frm_add_subject").serialize()).then(response => {
				if (response['data'].status == '1') {
					this.setState({ showLoader: false, saveText: 'Update' });
					this.loadSubject();
					notify.show(response['data'].message, 'success');
				}
			});
		}
		catch (error) {
			console.log(error);
		}
	}

	getPsoSelectOptions() {
		try {

			var d = [
				{ value: '1', label: 'PSO 1' },
				{ value: '2', label: 'PSO 2' },
				{ value: '3', label: 'PSO 3' },
				{ value: '4', label: 'PSO 4' },
				{ value: '5', label: 'PSO 5' },
				{ value: '6', label: 'PSO 6' },
				{ value: '7', label: 'PSO 7' }
			];
			return d;
		}
		catch (error) {
			return [];
		}
	}

	getPoSelectOptions() {
		try {

			var d = [
				{ value: '1', label: 'PO 1' },
				{ value: '2', label: 'PO 2' },
				{ value: '3', label: 'PO 3' },
				{ value: '4', label: 'PO 4' },
				{ value: '5', label: 'PO 5' },
				{ value: '6', label: 'PO 6' },
				{ value: '7', label: 'PO 7' }
			];
			return d;
		}
		catch (error) {
			return [];
		}
	}

	componentDidMount() {
		this.loadSubject();
	}

	loadSubject() {
		try {

			this.setState({ showLoader: true });
			var subid = this.props.match.params.id;
			API.get('v1/getpsosubjectbyid/' + subid).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					this.setState({ dataList: d, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	getValue(field) {
		var d = this.state.dataList;
		return d[field];
	}

	getDefaultPsoSelected(field) {
		try {

			var id = this.getValue(field);
			var arr = id.split(",");
			var d = [];
			if (arr != '') {
				arr.map((item, i) => {
					d.push({ value: item, label: 'PSO ' + item });
				});
			}
			return d;
		}
		catch (error) {

		}
	}

	getDefaultPoSelected(field) {
		try {

			var id = this.getValue(field);
			var arr = id.split(",");
			var d = [];
			if (arr != '') {
				arr.map((item, i) => {
					d.push({ value: item, label: 'PO ' + item });
				});
			}
			return d;
		}
		catch (error) {

		}
	}
	
	getDefaultColSelected(field) {
		try {

			var id = this.getValue(field);
			var arr = id;
			var d = [];
			if (arr != '') {
				d.push({ value: id, label: id });
			}
			return d;
		}
		catch (error) {

		}
	}
	
	getCoLSelectOptions() {
		try {

			var d = [
				{ value: 'K', label: 'K' },
				{ value: 'U', label: 'U' },
				{ value: 'A', label: 'A' },
				{ value: 'An', label: 'An' },
				{ value: 'C', label: 'C' },
				{ value: 'E', label: 'E' },
			];
			return d;
		}
		catch (error) {
			return [];
		}
	}

	render() {

		return (
			<div>

				<LineLoader loading={this.state.showLoader} />

				<Menu />

				<div className="pt-50" >

					<form id="frm_add_subject" action="" method="post" onSubmit={this.handleSubmit}>
						<input type="hidden" name="i__userId" value={getls('user', 'id')} />
						<input type="hidden" name="i__id" value={this.getValue('id')} />
						<input type="hidden" name="i__co1_pso" value={this.getValue('co1_pso')} />
						<input type="hidden" name="i__co1_po" value={this.getValue('co1_po')} />
						<input type="hidden" name="i__co2_pso" value={this.getValue('co2_pso')} />
						<input type="hidden" name="i__co2_po" value={this.getValue('co2_po')} />
						<input type="hidden" name="i__co3_pso" value={this.getValue('co3_pso')} />
						<input type="hidden" name="i__co3_po" value={this.getValue('co3_po')} />
						<input type="hidden" name="i__co4_pso" value={this.getValue('co4_pso')} />
						<input type="hidden" name="i__co4_po" value={this.getValue('co4_po')} />
						<input type="hidden" name="i__co5_pso" value={this.getValue('co5_pso')} />
						<input type="hidden" name="i__co5_po" value={this.getValue('co5_po')} />
						<input type="hidden" name="i__co1_col" value={this.getValue('co1_col')} />
						<input type="hidden" name="i__co2_col" value={this.getValue('co2_col')} />
						<input type="hidden" name="i__co3_col" value={this.getValue('co3_col')} />
						<input type="hidden" name="i__co4_col" value={this.getValue('co4_col')} />
						<input type="hidden" name="i__co5_col" value={this.getValue('co5_col')} />

						<Row>
							<Col md={12} >
								<Row>
									<Col md={2} ><Label>Subject Code <span className="required">*</span> </Label></Col>
									<Col md={3}>
										<Input type="text" size="sm" name="i__subCode" id="i__subCode" required value={this.getValue('subcode')} />
									</Col>
									<Col md={2} ><Label>Subject Name <span className="required">*</span> </Label></Col>
									<Col md={5}>
										<Input type="text" size="sm" name="i__subject" required style={{ textTransform: 'uppercase' }} value={this.getValue('subname')} />
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className="mt-20">
							<Col md={12}>
								<Table>
									<thead>
										<tr>
											<th>CO</th>
											<th>PSO</th>
											<th>PO</th>
											<th>Cognitive Level</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Co 1</td>
											<td>
												<Select isMulti value={this.getDefaultPsoSelected('co1_pso')}
													onChange={this.handlePsoSelectChange.bind(this, 'co1_pso')}
													options={this.getPsoSelectOptions()} />
											</td>
											<td>
												<Select isMulti value={this.getDefaultPoSelected('co1_po')}
													onChange={this.handleSelectChange.bind(this, 'co1_po')}
													options={this.getPoSelectOptions()} />
											</td>
											<td>
												<Select value={this.getDefaultColSelected('co1_col')}
													onChange={this.handleCoLSelectChange.bind(this, 'co1_col')}
													options={this.getCoLSelectOptions()} />
											</td>
										</tr>
										<tr>
											<td>Co 2</td>
											<td>
												<Select isMulti value={this.getDefaultPsoSelected('co2_pso')}
													onChange={this.handlePsoSelectChange.bind(this, 'co2_pso')}
													options={this.getPsoSelectOptions()} />
											</td>
											<td>
												<Select isMulti value={this.getDefaultPoSelected('co2_po')}
													onChange={this.handleSelectChange.bind(this, 'co2_po')}
													options={this.getPoSelectOptions()} />
											</td>
											<td>
												<Select value={this.getDefaultColSelected('co2_col')}
													onChange={this.handleCoLSelectChange.bind(this, 'co2_col')}
													options={this.getCoLSelectOptions()} />
											</td>
										</tr>
										<tr>
											<td>Co 3</td>
											<td>
												<Select isMulti value={this.getDefaultPsoSelected('co3_pso')}
													onChange={this.handlePsoSelectChange.bind(this, 'co3_pso')}
													options={this.getPsoSelectOptions()} />
											</td>
											<td>
												<Select isMulti value={this.getDefaultPoSelected('co3_po')}
													onChange={this.handleSelectChange.bind(this, 'co3_po')}
													options={this.getPoSelectOptions()} />
											</td>
											<td>
												<Select value={this.getDefaultColSelected('co3_col')}
													onChange={this.handleCoLSelectChange.bind(this, 'co3_col')}
													options={this.getCoLSelectOptions()} />
											</td>
										</tr>
										<tr>
											<td>Co 4</td>
											<td>
												<Select isMulti value={this.getDefaultPsoSelected('co4_pso')}
													onChange={this.handlePsoSelectChange.bind(this, 'co4_pso')}
													options={this.getPsoSelectOptions()} />
											</td>
											<td>
												<Select isMulti value={this.getDefaultPoSelected('co4_po')}
													onChange={this.handleSelectChange.bind(this, 'co4_po')}
													options={this.getPoSelectOptions()} />
											</td>
											<td>
												<Select value={this.getDefaultColSelected('co4_col')}
													onChange={this.handleCoLSelectChange.bind(this, 'co4_col')}
													options={this.getCoLSelectOptions()} />
											</td>
										</tr>
										<tr>
											<td>Co 5</td>
											<td>
												<Select isMulti value={this.getDefaultPsoSelected('co5_pso')}
													onChange={this.handlePsoSelectChange.bind(this, 'co5_pso')}
													options={this.getPsoSelectOptions()} />
											</td>
											<td>
												<Select isMulti value={this.getDefaultPoSelected('co5_po')}
													onChange={this.handleSelectChange.bind(this, 'co5_po')}
													options={this.getPoSelectOptions()} />
											</td>
											<td>
												<Select value={this.getDefaultColSelected('co5_col')}
													onChange={this.handleCoLSelectChange.bind(this, 'co5_col')}
													options={this.getCoLSelectOptions()} />
											</td>
										</tr>
									</tbody>
								</Table>
							</Col>
						</Row>
						<Row>
							<Col md={11}></Col>
							<Col md={1}>
								<Button type="submit" color="primary" disabled={this.state.saveText != 'Update' ? true : false}>
									{this.state.saveText != 'Update' ? <span className="ion-loading-a" ></span> : ''} {this.state.saveText}
								</Button>
							</Col>
						</Row>
					</form>

				</div>

			</div>
		);
	}

}

export default EditSubject;
