import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from "jquery";
import { Nav, NavItem } from 'reactstrap';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {

        return (
            <div className="page-sub-nav page-sub-nav-fixed">
                <Nav pills>
                    <NavItem>
                        <NavLink to="/app/program-specific-outcomes" exact >
                            PO PSO & Cognitive Level
						</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/app/program-specific-outcomes/add" exact >
                            Internal Mark Entry
						</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/app/program-specific-outcomes/bestofpso" exact >
                           Seminar & Assignment
						</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/app/program-specific-outcomes/external" exact >
                            External Mark Entry
						</NavLink>
                    </NavItem>
					<NavItem>
                        <NavLink to="/app/program-specific-outcomes/courseattain" exact >
                            Course Attainment
						</NavLink>
                    </NavItem>
                </Nav>
            </div>
        );
    }

}
export default Menu;
