import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, Collapse} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, getls} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';
import JoditEditor from "jodit-react";
import "jodit/build/jodit.css";

import ModalFileBrowser from '../filebrowser/ModalFileBrowser';

import NOIMAGE_WIDE from '../../../assets/images/noimage-wide.png';

import Menu from './menu';


const styles = {
	imgCloseBtn: {
		position: 'absolute',
		right: '21px',
		top: '69px',
		background: '#ff2828',
		color: '#fff',
		padding: '0px 3px',
		borderRadius: '100px',
		fontSize: '11px',
		cursor: 'pointer',
	}
}

class EditNewsandEvents extends Component {
	constructor(props) {
		super(props);
		this.state = {
			btnSave: 'Update',
		  showStudentModal: false,
		  showFileManager: false,
		  showEditModal: false,
		  isActive: true,
		  showInTop: false,
		  editSlug: false,
		  showInUp: false,
		  pageData: '',
		  inTab: false,
		  inFeatureImage: false,
		  selectedFilename: '',
		  selectedUrl: '',
		  pageUrl: '',
		  editData: [],
		}
		
		this.handlePageContentChange = this.handlePageContentChange.bind(this);
		this.onContentEditorChange = this.onContentEditorChange.bind(this);
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);	
		this.removeSelectedFeatureImage = this.removeSelectedFeatureImage.bind(this);	
		this.handleTitleChange = this.handleTitleChange.bind(this);	
		this.handleFormSubmit = this.handleFormSubmit.bind(this);	
		this.handleJoditChange = this.handleJoditChange.bind(this);
	}
	
	componentDidMount(){
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			var id = this.props.match.params.id;
			var slug = this.props.match.params.slug;
			API.get('v1/news/'+id+'/'+slug).then(res=>{
				if(res['data'].status=='1'){
					var d = res['data'].data[0];
					this.setState({editData: d, pageData: d['content'], showInTop: d['showintop']=='1', showInUp: d['showinupcoming']=='1', isActive: d['activestatus']=='1', showLoader: false});
				}
				else{
					notify.show(res['data'].message, 'error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	 onContentEditorChange( evt ) {
		 try{
			this.setState({ pageData: evt.editor.getData() });
		 }
		 catch(error){
			 
		 }
    }


	handlePageContentChange(evt){
		try{
			
			this.setState({pageData: evt.target.value});
		}
		catch(error){
			
		}
	}
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedFilename: files[0].data.name,
				selectedUrl: url
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	removeSelectedFeatureImage(){
		this.setState({ selectedFilename: '',selectedUrl: '' });
	}
	
	
	handleTitleChange(e){
		try{
			var str = e.target.value;
			var newString = str.replace(/[^A-Z0-9]/ig, "-");
			this.setState({pageUrl: newString.toLowerCase()});
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({btnSave: 'Updating..', showLoader: true});
			
			API.post('v1/news/update/', $("#frmAdd_News").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					this.setState({btnSave: 'Update', showLoader: false});
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({btnSave: 'Update', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnSave: 'Update', showLoader: false});
		}
	}
	
	getField(fieldName){
		try{
			var d = this.state.editData;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	handleJoditChange(content){
		
		this.setState({pageData: content});
	}
	
	
	render(){
		
	let sno = 1;
		
	  return (
	  <div>
			
			<Menu />
			
			<LineLoader loading={this.state.showLoader} />
			<PageLoader loading={this.state.showLoader} />
			
			<div className="pt-50" >
			
				<form action="" method="post" encType="multipart/form-data" id="frmAdd_News" onSubmit={this.handleFormSubmit} >
			
				<input type="hidden" name="i__type" value="events" />
				<input type="hidden" name="i__id" value={this.getField('id')} />
				
				<Row>
					<Col md={9} >
						<Card>
							<CardBody>
								<Row>
									<Col md={12} >
										<Label>Title <span className="required">*</span></Label>
										<Input type="text" name="i__title" size="sm" required placeholder="Title" onChange={this.handleTitleChange} defaultValue={this.getField('title')} />
									</Col>
								</Row>

								<Row className="mt-20" >
									<Col md={12} >
										<Label>Page Content <span className="required">*</span>
											<div style={{position: 'absolute',top: '-1px',left: '150px'}} >
												
														<Button size="xs" color="info" onClick={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} >
															Open Filemanager
														</Button>
											</div>
										</Label>
										{this.state.showLoader ? null : 
										<div className="page-content-editor" >
										 <JoditEditor
												value={this.state.pageData}
												onBlur={this.handleJoditChange}
												onChange={this.handleJoditChange}
											/>
										</div>
										}
									</Col>
								</Row>
							</CardBody>
						</Card>
						
					</Col>
					
					<Col md={3} >
						
						<Card>
							<CardBody>
							{/*<Row>
									<Col md={12} >
										 <div className="pretty p-icon p-smooth">
											<input type="checkbox" name="i__showInTop" defaultChecked={this.state.showInTop} />
											<div className="state p-success">
												<i className="icon ion-ios7-checkmark-empty"></i>
												<label>Show in top scroll</label>
											</div>
										</div>
									</Col>
							</Row>*/}
								<Row>
									<Col md={12} >
										 <div className="pretty p-icon p-smooth">
										<input type="checkbox" name="i__showInUp" checked={this.state.showInUp} onChange={()=>{this.setState({showInUp: !this.state.showInUp})}} />
											<div className="state p-success">
												<i className="icon ion-ios7-checkmark-empty"></i>
												<label>Show in Upcoming Event</label>
											</div>
										</div>
									</Col>
								</Row>
								{/*<Row className="mt-10" >
									<Col md={12} >
										<Label>Category</Label>
										<Input type="select" size="sm" name="i__pageCategory" >
											<option value="withsidebar" > Page With Sidebar </option>
											<option value="content"> Content Only </option>
										</Input>
									</Col>
								</Row>*/}
								<Row className="mt-10" >
									<Col md={12} >
										<hr />
										<div className="float-left">
											 <div className="pretty p-icon p-smooth">
												<input type="checkbox" name="i__active" checked={this.state.isActive} onChange={()=>{this.setState({isActive: !this.state.isActive})}}  />
												<div className="state p-success">
													<i className="icon ion-ios7-checkmark-empty"></i>
													<label>Active</label>
												</div>
											</div>
										</div>
										<div className="float-right" >
										<Button type="submit" size="sm" color="primary" disabled={this.state.btnSave!='Update'} >
											{this.state.btnSave=='Update' ? 
												'' :
												<span className="ion-loading-a" ></span>} &nbsp;
											{this.state.btnSave}
										</Button>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
						
						{/*<Card>
							<CardBody style={{ paddingBottom: '0px' }} >
								<CardTitle>
									<div className="pretty p-icon p-smooth">
										<input type="checkbox" name="i__inTab" defaultChecked={this.state.inTab} onChange={()=>{this.setState({inTab: !this.state.inTab})}}  />
										<div className="state p-primary">
											<i className="icon ion-ios7-checkmark-empty"></i>
											<label>Display in Tab</label>
										</div>
									</div>
								</CardTitle>
								<hr style={{marginBottom:'0px'}} />
							</CardBody>
							<Collapse isOpen={this.state.inTab}>
								<CardBody style={{paddingTop: '5px', marginTop: '0px' }} >
									<Row>
										<Col md={12} >
											<Label>Tab Title</Label>
											<Input type="text" name="i__tabTitle" size="sm" />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={12} >
											<Label>Tab Order</Label>
											<Input type="number" name="i__tabOrder" size="sm" />
										</Col>
									</Row>
									<Row className="mt-10" >
										<Col md={12} >
											<Label>Under Menu</Label>
											<select name="i__tabMenu" className="form-control form-control-sm" >
											
											</select>
										</Col>
									</Row>
								</CardBody>
							</Collapse>
						</Card>*/}
						
						<Card>
							<CardBody  >
								<CardTitle>
									Event Info
								</CardTitle>
								<hr />
								<Row>
									<Col md={12} >
										<Label>Event Start Date</Label>
										<Input type="date" name="i__evtStartDt" size="sm" defaultValue={this.getField('eventstartdate')} />
									</Col>
								</Row>
								<Row className="mt-10" >
									<Col md={12} >
										<Label>Event End Date</Label>
										<Input type="date" name="i__evtEndDt" size="sm" defaultValue={this.getField('eventenddate')} />
									</Col>
								</Row>
								<Row className="mt-10" >
									<Col md={12} >
										<Label>Event Venue</Label>
										<Input type="text" name="i__evtVenue" size="sm" defaultValue={this.getField('eventvenue')}  />
									</Col>
								</Row>
							</CardBody>
						</Card>
						
					</Col>
				</Row>
				
				<input type="hidden" name="i__pageData" value={this.state.pageData} />
				<input type="hidden" name="i__featureImage" value={this.state.selectedUrl} />
				<input type="hidden" name="i__userId" value={getls('user','id')} />
				
				</form>
				
			</div>
			
			<ModalFileBrowser isOpen={this.state.showfileBrowser} 
				atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} >
					
			</ModalFileBrowser>		
		
		</div>
	  );
	}

}

export default EditNewsandEvents;
