 import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Notifications, {notify} from 'react-notify-toast';
import { Loader, Switch } from '../../../vibe/';
import {AppName, Version, ClientName, Api} from '../../../variables';
import API from '../../../config/API';
import {setls, upperCase,capitalizeFirst, groupByMultiple, getls} from '../../../config';

import ModalFileBrowser from '../filebrowser/ModalFileBrowser';
import NOIMAGE_WIDE from '../../../assets/images/noimage-wide.png';

const styles = {
	ftCss: {
		borderTop: '1px solid rgba(34,36,38,.15)',
		padding: '10px 0px 0px 0px',
	}
}

class AddSlider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			btnSave: 'Save',
			showLoader: false,
			linkList: [],
			selectedImage: [],
			selectedFilename: '',
			selectedUrl: '',
		}
		this.handleFileBrowserClick = this.handleFileBrowserClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
	
	}
	
	handleFileBrowserClick(files){
		try{
			
			
			var url = files[0].data.path +'/'+ files[0].data.name;
			url= url.replace(/\/\//g, "/");
			
			this.setState({
				selectedImage: files,
				selectedFilename: files[0].data.name,
				selectedUrl: url,
				showfileBrowser: false,
			});
			//selectedUrl: files[0].path
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, btnSave: 'Updating..'});
			API.post('v1/sliders/save', $("#frm_addSlider").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					document.getElementById("frm_addSlider").reset();
					this.setState({showLoader: false, btnSave: 'Save', selectedUrl: '', selectedFilename: '',  selectedImage: []});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnSave: 'Save'});
				}
			});
		}
		catch(error){
			alert('Unexpected Error');
			this.setState({showLoader: false, btnSave: 'Save'});
		}
	}
	

	
	
	render(){
		
		return(
			<div>
				<form action="" method="post" encType="multipart/form-data" id="frm_addSlider" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="i__userId" value={getls('user','id')} />
					<input type="hidden" name="i__fileUrl" value={this.state.selectedUrl} />
					
					<Row>
						<Col md={12} >
							<Label>Slider Image <span className="required"> (1920 x 800)</span></Label>
							
							{this.state.selectedUrl!=null && this.state.selectedUrl!=undefined && this.state.selectedUrl.length > 4 ? 
								<div>
									<img src={Api.ImageUploadPath+this.state.selectedUrl} style={{width: '100%', maxHeight: '300px', cursor: 'pointer' }} onClick={()=>{this.setState({showfileBrowser: true})}} />
								</div>
							:
								<div>
									
									<img src={NOIMAGE_WIDE} style={{width: '100%', maxHeight: '300px', cursor: 'pointer' }} onClick={()=>{this.setState({showfileBrowser: true})}}  />
								</div>
							}
							
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={10} >
							<Label>Slider Title <span className="required"></span></Label>
							<Input type="text" name="i__title" size="sm"  placeholder="Slider Title" />
						</Col>
						<Col md={2} >
							<Label>Slider Order <span className="required"></span></Label>
							<Input type="number" name="i__order" size="sm"  placeholder="Order" />
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<Label>Slider Content <span className="required"></span></Label>
							<Input type="textarea" name="i__content" size="sm"  placeholder="Slider Content" rows="3"  />
						</Col>
					</Row>
					
					<div style={styles.ftCss} className="mt-20" >
						<div className="float-left" >
							<div className="pretty p-default">
								<input type="checkbox" name="i__active"  defaultChecked={true} />
								<div className="state p-success">
									<label>Active</label>
								</div>
							</div>
						</div>
						<div className="float-right" >
							<Button type="submit" color="success" size="sm" disabled={this.state.btnSave!='Save'} >
								{this.state.btnSave=='Save' ? 
								<span className="ion-checkmark"></span> : 
								<span className="ion-loading-a"></span>}
								&nbsp; {this.state.btnSave}
							</Button>
							&nbsp;
							<Button type="reset" color="danger" size="sm" onClick={this.props.atClose} >
								Cancel 
							</Button>
							
						</div>
					</div>
				
				</form>
				
				
				<ModalFileBrowser isOpen={this.state.showfileBrowser} 
				atClose={()=>{this.setState({showfileBrowser: !this.state.showfileBrowser})}} 
				fileBrowserClick={this.handleFileBrowserClick} >
													
				</ModalFileBrowser>
			</div>
		);
	}

}

export default AddSlider;
