import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import { Loader } from "../../../vibe";
import { AppName, Version, ClientName, Api } from "../../../variables";
import { LineLoader, PageLoader, Tabs } from "../../../elements";
import API from "../../../config/API";
import {
  setls,
  upperCase,
  capitalizeFirst,
  groupByMultiple,
  getSortOrder,
  getls,
} from "../../../config";
import CKEditor from "ckeditor4-react";

import ModalFileBrowser from "../../app/filebrowser/ModalFileBrowser";

import Menu from "./menu";

const styles = {
  courseBtn: {
    position: "fixed",
    top: "51px",
    right: "0",
    zIndex: "99",
  },
};

class DeptFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      btnSubmit: "Update Page",
      aboutData: "",
      data: [],
      pageCategory: "feedback",
      deptId: getls("user", "deptid"),
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onContentEditorChange = this.onContentEditorChange.bind(this);
  }

  componentDidMount() {
    this.loadPageContent();
  }

  loadPageContent() {
    try {
      this.setState({ showLoader: true });

      API.get(
        "v1/department/page/" +
          this.state.deptId +
          "/" +
          this.state.pageCategory
      ).then((res) => {
        if (res["data"].status == "1") {
          this.setState({
            data: res["data"].data,
            aboutData: res["data"].data.pagecontent,
            showLoader: false,
          });
        } else {
          //notify.show(res['data'].message,'error');
          this.setState({ showLoader: false });
        }
      });
    } catch (error) {
      console.log(error);
      this.setState({ showLoader: false });
    }
  }

  onContentEditorChange(evt) {
    try {
      this.setState({ aboutData: evt.editor.getData() });
    } catch (error) {}
  }

  handleFormSubmit(e) {
    e.preventDefault();
    try {
      if (
        !this.state.aboutData ||
        !this.state.aboutData == null ||
        this.state.aboutData.length < 1
      ) {
        notify.show("Please enter Page Content", "warning");
        return false;
      }

      this.setState({ showLoader: true, btnSubmit: "Updating.." });
      API.post(
        "v1/department/page/save",
        $("#frm_academic_About").serialize()
      ).then((res) => {
        if (res["data"].status == "1") {
          notify.show(res["data"].message, "success");
          var d = this.state.data;
          d["id"] = res["data"].pageid;
          this.setState({
            showLoader: false,
            btnSubmit: "Update Page",
            data: d,
          });
        } else {
          notify.show(res["data"].message, "error");
          this.setState({ showLoader: false, btnSubmit: "Update Page" });
        }
      });
    } catch (error) {
      this.setState({ showLoader: false, btnSubmit: "Update Page" });
      console.log(error);
    }
  }

  getField(fieldName) {
    try {
      var d = this.state.data;
      if (d[fieldName]) {
        return d[fieldName];
      } else return "";
    } catch (error) {
      console.log(error);
    }
  }

  handleTextAreaChange(key, e) {
    try {
      var d = this.state.data;
      d[key] = e.target.value;
      this.setState({ data: d });
    } catch (error) {}
  }

  render() {
    return (
      <div>
        <PageLoader loading={this.state.showLoader} />
        <LineLoader loading={this.state.showLoader} />

        <Menu />

        <div className="pt-50">
          <form
            action=""
            method="post"
            encType="multipart/form-data"
            id="frm_academic_About"
            onSubmit={this.handleFormSubmit}
          >
            <input type="hidden" name="i__id" value={this.getField("id")} />
            <input
              type="hidden"
              name="i__category"
              value={this.state.pageCategory}
            />
            <input type="hidden" name="i__deptId" value={this.state.deptId} />
            <input type="hidden" name="i__userId" value={getls("user", "id")} />

            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <div className="text-left">
                          <div className="pretty p-icon p-smooth">
                            {this.getField("showinpage") == "1" ? (
                              <input
                                type="checkbox"
                                name="i__about_ShowInPage"
                                defaultChecked={true}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                name="i__about_ShowInPage"
                              />
                            )}
                            <div className="state p-success">
                              <i className="icon ion-ios7-checkmark-empty"></i>
                              <label>Show In Page</label>
                            </div>
                          </div>
                        </div>
                        <div className="text-right">
                          <Button
                            type="submit"
                            color="success"
                            size="sm"
                            disabled={this.state.btnSubmit != "Update Page"}
                          >
                            {this.state.btnSubmit == "Update Page" ? (
                              <span className="ion-android-checkmark"></span>
                            ) : (
                              <span className="ion-loading-a"></span>
                            )}{" "}
                            &nbsp;
                            {this.state.btnSubmit}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-20">
                      <Col md={12}>
                        <Label>
                          Activities Page Content{" "}
                          <span className="required">*</span>
                          <div
                            style={{
                              position: "absolute",
                              top: "0px",
                              left: "175px",
                            }}
                          >
                            <ModalFileBrowser
                              isOpen={this.state.showFileManager}
                              atClose={() => {
                                this.setState({
                                  showFileManager: !this.state.showFileManager,
                                });
                              }}
                            >
                              <Button
                                type="button"
                                size="xs"
                                color="info"
                                onClick={() => {
                                  this.setState({
                                    showFileManager: !this.state
                                      .showFileManager,
                                  });
                                }}
                              >
                                Open File Manager
                              </Button>
                            </ModalFileBrowser>
                          </div>
                        </Label>
                        {this.state.showLoader ? null : (
                          <CKEditor
                            name="editor_dept_about"
                            config={{
                              extraPlugins: "print,font",
                              height: "700",
                              startupShowBorders: "false",
                              allowedContent: true,
                              extraAllowedContent: "*(*);*{*}",
                            }}
                            data={this.state.aboutData}
                            onChange={this.onContentEditorChange}
                          />
                        )}
                      </Col>
                      <Col md={12} className="mt-15">
                        <div className="text-right">
                          <Button
                            type="submit"
                            color="success"
                            size="sm"
                            disabled={this.state.btnSubmit != "Update Page"}
                          >
                            {this.state.btnSubmit == "Update Page" ? (
                              <span className="ion-android-checkmark"></span>
                            ) : (
                              <span className="ion-loading-a"></span>
                            )}{" "}
                            &nbsp;
                            {this.state.btnSubmit}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <input
              type="hidden"
              name="i__about_Pagecontent"
              value={this.state.aboutData}
            />
          </form>
        </div>
      </div>
    );
  }
}

export default DeptFeedback;
