/***
 * @Global Variables
 *
 */

module.exports = {
  AppName: "Kalvisalai",

  ModuleName: "SMS Panel",

  Version: "1.0",

  AppHeaderText: "STCADMIN",

  /**
   * ClientName
   * name of the institution
   */
  ClientName: "Sarah Tucker College (Autonomous)",

  /**
   * ClientPlace
   * name of the institution place
   */
  ClientPlace: "Palayamkottai",

  /**
   * ClientAddress
   * address of the Institution
   */
  ClientAddress: "Palayamkottai, Tirunelveli - 627002",

  /**
   * Support  []
   * to hold support email id and mobile nos
   */
  Support: {
    Email: "peacesoft.developer@gmail.com",
    Mobile: "+91 9487900120",
  },

  /**
   * LSKEY
   * variable to hold the local storage in this name
   **/
  LSKEY: "stc26120",

  /**
   * Api
   * to hold api realted informations
   */
  Api: {
    /*Url: 'http://localhost/kalvisalai/stc/php/api/',
		SiteUrl: 'https://sarahtuckercollege.edu.in/',
		ImageUploadPath: 'http://localhost/kalvisalai/stc/php', //Don't put slash at last
		AcademicPath: 'https://admin.sarahtuckercollege.edu.in/api/',*/

    Url: "https://webadmin.sarahtuckercollege.edu.in/admin/api/",
    SiteUrl: "https://sarahtuckercollege.edu.in/",
    ImageUploadPath: "https://webadmin.sarahtuckercollege.edu.in/admin", //Don't put slash at last
    AcademicPath: "https://admin.sarahtuckercollege.edu.in/api/",
  },

  /**
   * ImgPath
   * to hold all the image paths
   */
  ImgPath: {
    StudentProfilePhoto:
      "https://stjohnscollege.kalvisalai.in/academic/admin/public/studentphoto/",
  },

  MonthList: [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],

  ExamMonths: ["April", "Nov"],

  PSO: {
    UNITS: [
      { id: "1", value: "Unit I" },
      { id: "2", value: "Unit II" },
      { id: "3", value: "Unit III" },
      { id: "4", value: "Unit IV" },
      { id: "5", value: "Unit V" },
    ],
    PARTS: [
      { id: "PART_A", value: "Part-A" },
      { id: "PART_B", value: "Part-B" },
      { id: "PART_C", value: "Part-C" },
    ],
    INTERNAL: {
      PART_A: ["1", "2", "3", "4"],
    },
  },
};
