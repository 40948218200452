import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from "jquery";
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import { AppName, Version, ClientName, Api, ExamMonths, PSO } from '../../../variables';
import API from '../../../config/API';
import { setls, getls, getlsItem, upperCase, capitalizeFirst, groupByMultiple, getDescSortOrder, decimalKeyPress } from '../../../config';
import { PageLoader, LineLoader } from '../../../elements';
import axios from 'axios';
import { AutoComplete } from 'primereact/autocomplete';

import Menu from './Menu';

class AddPso extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			academicList: [],
			degreeList: [],
			yearsList: [],
			courseList: [],
			subjectList: [],
			selectedCourse: '',
			selectedDept: getls('user', 'deptid'),
			selectedDegree: '',
			selectedBatch: '',
			viewBtnText: 'View',
			studentsList: [],
			studentsView: [],
			studentSuggestionValue: '',
			studentsSuggestion: [],
			selectedStudent: [],
			partAMax: '',
			partBMax: '',
			partCMax: '',
			studentMark: [],
			saveText: 'Save'
		}
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDegreeChange = this.handleDegreeChange.bind(this);
		this.handleYearChange = this.handleYearChange.bind(this);
		this.handleViewBtnClick = this.handleViewBtnClick.bind(this);
	}

	handleSubmit(e) {
		try {
			e.preventDefault();
			this.setState({ showLoader: true, saveText: 'Saving' });
			API.post('v1/savepso', $("#frm_add_pos_mark").serialize()).then(response => {
				if (response['data'].status == '1') {
					this.setState({ showLoader: false, saveText: 'Save', studentsList: [], studentMark: [] });
					document.getElementById('frm_add_pos_mark').reset();
					notify.show(response['data'].message, 'success');
					this.getMarks();
				}
			});
		}
		catch (error) {
			console.log(error);
		}
	}

	getMarks() {
		try {

			var form = new FormData();
			form.append('department', $("#i__deptId").val());
			form.append('course', this.state.selectedCourse);
			form.append('subject', $("#i__subject").val());
			form.append('semester', $("#i__semester").val());
			form.append('batch', $("#i__batch").val());

			this.setState({ showLoader: true });
			API.post('v1/getpsomark/', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					this.setState({ studentMark: d, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	loadSubject() {
		try {

			this.setState({ showLoader: true });
			var userid = getls('user', 'id');
			API.get('v1/getpsosubject/' + userid).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					this.setState({ subjectList: d, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	componentDidMount() {
		this.setState({ courseList: JSON.parse(getlsItem('courselist')) });
		this.loadAcademics();
		this.loadSubject();
	}

	loadAcademics() {
		try {

			this.setState({ showLoader: true });
			axios.get(Api.AcademicPath + '/course/list_current_academics').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					var dl = groupByMultiple(d, function (obj) {
						return [obj.type];
					});
					this.setState({ academicList: d, degreeList: dl, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	handleDegreeChange(e) {
		try {
			this.setState({ selectedCourse: e.target.value });
			var v = $("#i__course option:selected").data("type");
			var cname = $("#i__course option:selected").data("name");
			var d = this.state.academicList.filter(function (obj) {
				if(v == 'UG'){
					return (obj.type == v && obj.semester == 6);
				}
				else if(v == 'PG'){
					return (obj.type == v && obj.semester == 4);
				}
			});
			d.sort(getDescSortOrder('startyear'));
			this.setState({ yearsList: d, selectedDegree: v, cname: cname });
		}
		catch (error) { }
	}

	handleYearChange(e) {
		try {
			var v = e.target.value;
			var d = this.state.yearsList.filter(function (obj) {
				return (obj.startyear == v);
			});
			this.setState({ selectedBatch: d[0]['value'] });
		}
		catch (error) { }
	}

	handleViewBtnClick() {
		try {

			this.getMarks();
			if (this.state.selectedBatch == '' || this.state.selectedCourse == '' || this.state.selectedDegree == '' || this.state.selectedDept == '') {
				notify.show('Please selected [Batch, Course, Degree, Department]', 'warning');
				return;
			}
			this.setState({ showLoader: true, viewBtnText: '...', studentsList: [], studentsView: [], studentMark: [] });
			var form = new FormData();
			form.append('course', this.state.selectedCourse);
			form.append('batch', this.state.selectedBatch);
			form.append('degree', this.state.selectedDegree);
			form.append('deptid', this.state.selectedDept);
			axios.post(Api.AcademicPath + '/students/studentslist_pso', form).then(res => {
				if (res['data'].status == '1') {
					this.setState({ studentsList: res['data'].data, studentsView: res['data'].data });
				}
				else {
					notify.show(res['data'].msg, 'error');
				}
				this.setState({ showLoader: false, viewBtnText: 'View' });
			});
		}
		catch (error) {

		}
	}

	handleMarkChange(regno, type, unit, e) {
		try {
			var d = this.state.studentMark;
			var _scored = e.target.value;
			var _internal = type + '_co' + unit;
			var index = d.findIndex(function (obj) {
				return (obj.registerno == regno);
			});
			if (index > -1) {
				d[index][_internal] = _scored;
			}
			else {
				d.push({ registerno: regno, [_internal]: _scored });
			}
			this.setState({ studentMark: d });
			console.log(d);
			this.updateTotal();
		}
		catch (error) { }
	}

	getScoredMark(regno, type, unit) {
		try {
			var _internal = type + '_co' + unit;
			var d = this.state.studentMark;
			/*var d = this.state.studentMark.filter(function (obj) {
				return (obj.registerno == regno);
			});*/
			var index = d.findIndex(function (obj) {
				return (obj.registerno == regno);
			});
			if (index > -1) {
				return d[index][_internal];
			}
		}
		catch (error) { }
	}

	getInternalTotal(regno, internal) {
		try {
			var mark = 0;
			var d = this.state.studentMark.filter(function (obj) {
				if (obj.registerno == regno) {
					if (internal == 'internal1') {
						mark = obj.internal1_total;
					}
					if (internal == 'internal2') {
						mark = obj.internal2_total;
					}
					if (internal == 'internal3') {
						mark = obj.internal3_total;
					}
				};
			});
			return mark;
		}
		catch (error) { }
	}

	updateTotal() {

		var d = this.state.studentMark;
		d.map(function (obj, i) {
			var mark1 = 0;
			var mark2 = 0;
			var mark3 = 0;
			if ('internal1_co1' in obj) {
				mark1 += parseFloat(obj.internal1_co1);
			}
			if ('internal1_co2' in obj) {
				mark1 += parseFloat(obj.internal1_co2);
			}
			if ('internal1_co3' in obj) {
				mark1 += parseFloat(obj['internal1_co3']);
			}
			if ('internal1_co4' in obj) {
				mark1 += parseFloat(obj['internal1_co4']);
			}
			if ('internal1_co5' in obj) {
				mark1 += parseFloat(obj['internal1_co5']);
			}
			d[i]['internal1_total'] = mark1;

			if ('internal2_co1' in obj) {
				mark2 += parseFloat(obj['internal2_co1']);
			}
			if ('internal2_co2' in obj) {
				mark2 += parseFloat(obj['internal2_co2']);
			}
			if ('internal2_co3' in obj) {
				mark2 += parseFloat(obj['internal2_co3']);
			}
			if ('internal2_co4' in obj) {
				mark2 += parseFloat(obj['internal2_co4']);
			}
			if ('internal2_co5' in obj) {
				mark2 += parseFloat(obj['internal2_co5']);
			}
			d[i]['internal2_total'] = mark2;

			if ('internal3_co1' in obj) {
				mark3 += parseFloat(obj['internal3_co1']);
			}
			if ('internal3_co2' in obj) {
				mark3 += parseFloat(obj['internal3_co2']);
			}
			if ('internal3_co3' in obj) {
				mark3 += parseFloat(obj['internal3_co3']);
			}
			if ('internal3_co4' in obj) {
				mark3 += parseFloat(obj['internal3_co4']);
			}
			if ('internal3_co5' in obj) {
				mark3 += parseFloat(obj['internal3_co5']);
			}
			d[i]['internal3_total'] = mark3;
		});

		this.setState({ studentMark: d });
	}

	render() {

		let deptName = this.state.courseList.length > 0 ? this.state.courseList[0]['departmentname'] : '';
		let deptId = this.state.courseList.length > 0 ? this.state.courseList[0]['department'] : '';

		let semesterList = [];
		if (this.state.selectedDegree == 'UG') {
			semesterList = [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' },
				{ label: '3', value: '3' },
				{ label: '4', value: '4' },
				{ label: '5', value: '5' },
				{ label: '6', value: '6' }
			]
		}
		else if (this.state.selectedDegree == 'PG') {
			semesterList = [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' },
				{ label: '3', value: '3' },
				{ label: '4', value: '4' }
			]
		}
		else if (this.state.selectedDegree == 'Mphil') {
			semesterList = [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' }
			]
		}

		return (
			<div>

				<LineLoader loading={this.state.showLoader} />

				<Menu />

				<div className="pt-50" >

					<form id="frm_add_pos_mark" action="" method="post" onSubmit={this.handleSubmit}>
						<input type="hidden" name="i__userId" value={getls('user', 'id')} />
						<input type="hidden" name="i__deptId" id="i__deptId" value={deptId} />
						<input type="hidden" name="i__degree" id="i__degree" value={this.state.selectedDegree} />
						<input type="hidden" name="studentMark" value={JSON.stringify(this.state.studentMark)} />
						<Row>
							<Col md={12} >
								<Row>
									<Col md={1} ><Label>Department</Label></Col>
									<Col md={2}>
										<Input type="text" size="sm" name="i__deptName" value={deptName} required />
									</Col>
									<Col md={2} >
										<Input type="select" name="i__course" id="i__course" size="sm" required onChange={this.handleDegreeChange} >
											<option value=""> -Course- </option>
											{this.state.courseList.map((item, i) => {
												return <option key={i} value={item.id} data-type={item.type} data-name={item.coursename}> {item.coursename} ({item.coursetype == 'regular' ? 'R' : 'SF'})</option>;
											})
											}
										</Input>
									</Col>
									<Col md={2}>
										<Input type="select" name="i__subject" id="i__subject" size="sm" required onChange={(e) => { this.setState({ selectedSubject: e.target.value }) }} >
											<option value=""> -Subject- </option>
											{this.state.subjectList.map((item, i) => {
												return <option key={i} value={item.id} > {item.subcode} - {capitalizeFirst(item.subname)} </option>;
											})}
										</Input>
									</Col>
									<Col md={2}>
										<Input type="select" name="i__semester" id="i__semester" size="sm" required>
											<option value=""> -Semester- </option>
											{semesterList.map((item, i) => {
												return <option key={i} value={item.value} > {item.label} </option>;
											})}
										</Input>
									</Col>
									<Col md={2} className="pl-0" >
										<Input type="select" name="i__batch" size="sm" id="i__batch" onChange={this.handleYearChange} required >
											<option value=""> -Year- </option>
											{this.state.yearsList.map((item, i) => {
												return <option key={i} value={item.startyear} > {item.startyear} </option>;
											})
											}
										</Input>
									</Col>
									<Col md={1} >
										<div className="text-right" >
											<Button type="button" size="sm" color="info" onClick={this.handleViewBtnClick} disabled={this.state.viewBtnText != 'View'} >
												{this.state.viewBtnText != 'View' ? <i className="ion-loading-a"></i> : null}
												&nbsp;{this.state.viewBtnText}
											</Button>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>

						{Object.keys(this.state.studentsList).length > 0 ?
							<Row className="mt-10" >
								<Col md={12} >
									<Card>
										<CardBody>
											<Row>
												<Col md={2} >
													<Label>Internal Mark Entry</Label>
												</Col>
											</Row>
											<hr className="my-0" />
										</CardBody>
										<CardBody>
											<Table className="tbl" style={{ fontSize: '11px' }} responsive>
												<tbody>
													<tr style={{ background: '#00adff' }} >
														<td width="30" rowSpan="2" >S.No</td>
														<td rowSpan="2" >Reg.No</td>
														<td rowSpan="2" >Student Name</td>
														<td colSpan="6" align="center">Internal I</td>
														<td colSpan="6" align="center">Internal II</td>
														<td colSpan="6" align="center">Internal III</td>
													</tr>
													<tr style={{ background: 'rgb(73, 255, 146)' }}>
														{PSO.UNITS.map((item, i) => {
															return <td align="center" >CO{i + 1}</td>;
														})}
														<td align="center" >Total</td>
														{PSO.UNITS.map((item, i) => {
															return <td align="center" >CO{i + 1}</td>;
														})}
														<td align="center" >Total</td>
														{PSO.UNITS.map((item, i) => {
															return <td align="center" >CO{i + 1}</td>;
														})}
														<td align="center" >Total</td>
													</tr>
													{/*<tr style={{ background: 'rgb(73, 255, 146)' }}  >
														{PSO.UNITS.map((item, i) => {
															return <React.Fragment>
																<td align="center" >Max</td>
																<td align="center" >Scored</td>
															</React.Fragment>;
														})
														}
														<td align="center" >Max</td>
														<td align="center" >Scored</td>
													</tr>*/}
													{this.state.studentsList.map((item, i) => {
														return <tr key={i}>
															<td>{i + 1}</td>
															<td>{item.registerno}</td>
															<td>{item.name}</td>
															{PSO.UNITS.map((item1, i1) => {
																return <td align="center" >
																	<Input type="number" style={{ fontSize: '11px' }} size="sm" max="20" 
																		onChange={this.handleMarkChange.bind(this, item.registerno, 'internal1', i1 + 1)}
																		value={this.getScoredMark(item.registerno, 'internal1', i1 + 1)} />
																</td>;
															})}
															<td align="center" >{this.getInternalTotal(item.registerno, 'internal1')}</td>
															{PSO.UNITS.map((item2, i2) => {
																return <td align="center" >
																	<Input type="number" style={{ fontSize: '11px' }} size="sm" max="20"
																		onChange={this.handleMarkChange.bind(this, item.registerno, 'internal2', i2 + 1)}
																		value={this.getScoredMark(item.registerno, 'internal2', i2 + 1)} />
																</td>;
															})}
															<td align="center" >{this.getInternalTotal(item.registerno, 'internal2')}</td>
															{PSO.UNITS.map((item3, i3) => {
																return <td align="center" >
																	<Input type="number" style={{ fontSize: '11px' }} size="sm" max="20"
																		onChange={this.handleMarkChange.bind(this, item.registerno, 'internal3', i3 + 1)}
																		value={this.getScoredMark(item.registerno, 'internal3', i3 + 1)} />
																</td>;
															})}
															<td align="center" >{this.getInternalTotal(item.registerno, 'internal3')}</td>
														</tr>
													})}

												</tbody>
											</Table>
										</CardBody>
										<CardBody>
											<Row>
												<Col md="11"></Col>
												<Col md={1} >
													<Button type="submit" color="primary" disabled={this.state.saveText != 'Save' ? true : false}>
														{this.state.saveText != 'Save' ? <span className="ion-loading-a" ></span> : ''} {this.state.saveText}
													</Button>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>
							: null}

					</form>

				</div>

			</div>
		);
	}

}

export default AddPso;
