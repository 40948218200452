import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { Nav, NavItem } from "reactstrap";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="page-sub-nav page-sub-nav-fixed">
        <Nav pills>
          <NavItem>
            <NavLink to="/dept/academics" exact>
              Courses
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/dept/academics/bos">Board Of Study</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/dept/academics/slider" exact>
              Slider
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/dept/academics/about">About</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/dept/academics/vision">Vision & Mission</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/dept/academics/syllabus">Syllabus</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/dept/academics/pso">PSO</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/dept/academics/teachingplan">Teaching Plan</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/dept/academics/ematerials">E-Materials</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/dept/academics/awards">Awards</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/dept/academics/highlights">Highlights</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/dept/academics/activities">Activities</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/dept/academics/feedback">Feedback</NavLink>
          </NavItem>
        </Nav>
      </div>
    );
  }
}
export default Menu;
