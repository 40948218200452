import React, {Component} from 'react';
import $ from "jquery";
import {Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, ButtonGroup, Table, Modal, ModalHeader, ModalBody, Collapse} from 'reactstrap';
import  {notify} from 'react-notify-toast';
import CKEditor from 'ckeditor4-react';
import { Loader } from '../../../vibe/';
import {AppName, Version, Api} from '../../../variables';
import API from '../../../config/API';
import {groupByMultiple, capitalizeFirst, upperCase, getls} from '../../../config';
import {PageLoader, LineLoader} from '../../../elements';

import ModalFileBrowser from '../filebrowser/ModalFileBrowser';

import NOIMAGE_WIDE from '../../../assets/images/noimage-wide.png';

import Menu from './menu';


const styles = {
	imgCloseBtn: {
		position: 'absolute',
		right: '21px',
		top: '69px',
		background: '#ff2828',
		color: '#fff',
		padding: '0px 3px',
		borderRadius: '100px',
		fontSize: '11px',
		cursor: 'pointer',
	}
}

class AddAnnouncement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			btnSave: 'Update',
			showStudentModal: false,
			showInTop: false,
			pageData: '',
		}
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.onContentEditorChange = this.onContentEditorChange.bind(this);
		this.handlePageContentChange = this.handlePageContentChange.bind(this);
	}
	
	 onContentEditorChange( evt ) {
		 try{
			this.setState({ pageData: evt.editor.getData() });
		 }
		 catch(error){
			 
		 }
    }


	handlePageContentChange(evt){
		try{
			
			this.setState({pageData: evt.target.value});
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({btnSave: 'Updating..', showLoader: true});
			
			API.post('v1/news/save/', $("#frm_add_news").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].message,'success');
					document.getElementById("frm_add_news").reset();
					this.setState({btnSave: 'Update', showLoader: false});
					if(this.props.atFinish)
						this.props.atFinish();
				}
				else
				{
					notify.show(res['data'].message,'error');
					this.setState({btnSave: 'Update', showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({btnSave: 'Update', showLoader: false});
		}
	}
	
	
	render(){
		
		
	  return (
	  <div>
			<form action="" method="post" encType="multipart/form-data" id="frm_add_news" onSubmit={this.handleFormSubmit} >
		
			<input type="hidden" name="i__userId" value={getls('user','id')} />
			<input type="hidden" name="i__type" value="announcement" />
			<input type="hidden" name="i__active" value="1" />
			<input type="hidden" name="i__pageData" value={this.state.pageData} />
			
				<Row>
					<Col md={12} >
						<Label>Announcement Title <span className="required"></span></Label>
						<Input type="textarea" name="i__title" size="sm" rows="1" />
					</Col>
				</Row>
				
				<Row>
					<Col md={12} >
						<Label>Announcement Content <span className="required">*</span></Label>
						{this.state.showLoader ? null : <CKEditor 
						name='add_even_ad_content' 
						config={{ 
						toolbar: [
							[ 'Bold', 'Italic' ],
							[ 'Cut', 'Copy' ],
							['Styles', 'Format', 'FontSize', 'TextColor', 'BGColor' ],
							[ 'NumberedList', 'BulletedList', 'JustifyLeft', 'JustifyCenter', 'JustifyBlock', 'JustifyRight', 'Blockquote' ],
							['Link', 'Image', 'Source', 'Maximize']
						],	
						height: '300', 
						startupShowBorders: 'false', 
						allowedContent: true, 
						extraAllowedContent: '*(*);*{*}' }} 
						data={this.state.pageData} 
						onChange={this.onContentEditorChange} />
						}
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={12} >
						<Label>Announcement Link <span className="required"></span></Label>
						<Input type="url" name="i__link" size="sm"  />
					</Col>
				</Row>
				
				<Row className="mt-15" >
					<Col md={4} >
						<Label>Expired On <span className="required"></span></Label>
						<Input type="date" name="i__expired" size="sm"  />
					</Col>
					<Col md={4} >
					<div className="pretty p-icon p-smooth" style={{marginTop: '38px' }} >
					<input type="checkbox" name="i__showInTop" checked={this.state.showInTop} onChange={()=>{this.setState({showInTop: !this.state.showInTop})}} />
						<div className="state p-success">
							<i className="icon ion-ios7-checkmark-empty"></i>
							<label>Show in top scrolling</label>
						</div>
					</div>
					</Col>
					<Col md={4} >
						<div className="text-right mt-25" >
							<Button type="submit" size="sm" color="success" disabled={this.state.btnSave!='Update'} >
								{this.state.btnSave=='Update' ?  '' :
									<span className="ion-loading-a" ></span>} &nbsp;
								{this.state.btnSave}
							</Button>
						</div>
					</Col>
				</Row>
			
			</form>
		
		</div>
	  );
	}

}

export default AddAnnouncement;
