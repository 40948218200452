import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from "jquery";
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, Input, Button, Table } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { Doughnut, Line } from 'react-chartjs-2';
import { Loader } from '../../../vibe/';
import { AppName, Version, ClientName, Api, ExamMonths, PSO } from '../../../variables';
import API from '../../../config/API';
import { setls, getls, getlsItem, upperCase, capitalizeFirst, groupByMultiple, getDescSortOrder, decimalKeyPress } from '../../../config';
import { PageLoader, LineLoader } from '../../../elements';
import axios from 'axios';
import { AutoComplete } from 'primereact/autocomplete';

import Menu from './Menu';
import Chart from 'react-apexcharts';

const tdStyle = { border: '1px solid black', padding: '5px' }
const inputStyle = { fontSize: '11px',width : '50px' }

class ExternalMark extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			academicList: [],
			degreeList: [],
			yearsList: [],
			courseList: [],
			subjectList: [],
			selectedCourse: '',
			selectedDept: getls('user', 'deptid'),
			selectedDegree: '',
			selectedBatch: '',
			viewBtnText: 'View',
			studentsList: [],
			studentsView: [],
			studentSuggestionValue: '',
			studentsSuggestion: [],
			selectedStudent: [],
			partAMax: '',
			partBMax: '',
			partCMax: '',
			studentMark: [],
			average: [],
			saveText: 'Save'
		}
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDegreeChange = this.handleDegreeChange.bind(this);
		this.handleYearChange = this.handleYearChange.bind(this);
		this.handleViewBtnClick = this.handleViewBtnClick.bind(this);
	}

	printChart() {
		$(".apexcharts-toolbar").css("display", "none");
		$("#printbtn").css("display", "none");
		$(".print_title").css("display", "block");
		var content = document.getElementById("print_chart");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		$(".apexcharts-toolbar").css("display", "block");
		$("#printbtn").css("display", "block");
		$(".print_title").css("display", "none");
		pri.document.close();
		pri.focus();
		pri.print();
	}

	handleSubmit(e) {
		try {
			e.preventDefault();
			this.setState({ showLoader: true, saveText: 'Saving' });
			API.post('v1/saveexternal', $("#frm_add_external_mark").serialize()).then(response => {
				if (response['data'].status == '1') {
					this.setState({ showLoader: false, saveText: 'Save', studentsList: [], studentMark: [] });
					document.getElementById('frm_add_external_mark').reset();
					notify.show(response['data'].message, 'success');
					this.getMarks();
				}
			});
		}
		catch (error) {
			console.log(error);
		}
	}

	getMarks() {
		try {
			var form = new FormData();
			form.append('department', $("#i__deptId").val());
			form.append('course', this.state.selectedCourse);
			form.append('subject', $("#i__subject").val());
			form.append('semester', $("#i__semester").val());
			form.append('batch', $("#i__batch").val());

			this.setState({ showLoader: true });
			API.post('v1/getexternalmark/', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					this.setState({ studentMark: d, showLoader: false });
					this.getAverage();
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	getAverage() {
		try {
			var form = new FormData();
			form.append('department', $("#i__deptId").val());
			form.append('course', this.state.selectedCourse);
			form.append('subject', $("#i__subject").val());
			form.append('semester', $("#i__semester").val());
			form.append('batch', $("#i__batch").val());

			this.setState({ showLoader: true });
			API.post('v1/getaverage/', form).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					this.setState({ average: d, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	loadSubject() {
		try {

			this.setState({ showLoader: true });
			var userid = getls('user', 'id');
			API.get('v1/getpsosubject/' + userid).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					this.setState({ subjectList: d, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	componentDidMount() {
		this.setState({ courseList: JSON.parse(getlsItem('courselist')) });
		this.loadAcademics();
		this.loadSubject();
	}

	loadAcademics() {
		try {

			this.setState({ showLoader: true });
			axios.get(Api.AcademicPath + '/course/list_current_academics').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					var dl = groupByMultiple(d, function (obj) {
						return [obj.type];
					});
					this.setState({ academicList: d, degreeList: dl, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	handleDegreeChange(e) {
		try {
			this.setState({ selectedCourse: e.target.value });
			var v = $("#i__course option:selected").data("type");
			var cname = $("#i__course option:selected").data("name");
			var d = this.state.academicList.filter(function (obj) {
				if(v == 'UG'){
					return (obj.type == v && obj.semester == 6);
				}
				else if(v == 'PG'){
					return (obj.type == v && obj.semester == 4);
				}
			});
			d.sort(getDescSortOrder('startyear'));
			this.setState({ yearsList: d, selectedDegree: v, cname: cname });
		}
		catch (error) { }
	}

	handleYearChange(e) {
		try {
			var v = e.target.value;
			var d = this.state.yearsList.filter(function (obj) {
				return (obj.startyear == v);
			});
			this.setState({ selectedBatch: d[0]['value'] });
		}
		catch (error) { }
	}

	handleViewBtnClick() {
		try {

			this.getMarks();
			if (this.state.selectedBatch == '' || this.state.selectedCourse == '' || this.state.selectedDegree == '' || this.state.selectedDept == '') {
				notify.show('Please selected [Batch, Course, Degree, Department]', 'warning');
				return;
			}
			this.setState({ showLoader: true, viewBtnText: '...', studentsList: [], studentsView: [], studentMark: [] });
			var form = new FormData();
			form.append('course', this.state.selectedCourse);
			form.append('batch', this.state.selectedBatch);
			form.append('degree', this.state.selectedDegree);
			form.append('deptid', this.state.selectedDept);
			axios.post(Api.AcademicPath + '/students/studentslist_pso', form).then(res => {
				if (res['data'].status == '1') {
					this.setState({ studentsList: res['data'].data, studentsView: res['data'].data });
				}
				else {
					notify.show(res['data'].msg, 'error');
				}
				this.setState({ showLoader: false, viewBtnText: 'View' });
			});
		}
		catch (error) {

		}
	}

	handleMarkChange(regno, type, e) {
		try {
			var d = this.state.studentMark;
			var _scored = e.target.value;
			var index = d.findIndex(function (obj) {
				return (obj.registerno == regno);
			});
			if (index > -1) {
				d[index][type] = _scored;
			}
			else {
				d.push({ registerno: regno, [type]: _scored });
			}
			this.setState({ studentMark: d });
		}
		catch (error) { }
	}

	getScoredMark(regno, type) {
		try {
			var d = this.state.studentMark;
			var index = d.findIndex(function (obj) {
				return (obj.registerno == regno);
			});
			if (index > -1) {
				return d[index][type];
			}
		}
		catch (error) { }
	}
	
	getTotalMark(regno){
		try {
			var d = this.state.studentMark;
			var mark = 0;
			var index = d.findIndex(function (obj) {
				return (obj.registerno == regno);
			});
			if (index > -1) {
				mark+=parseFloat(d[index]['one_m1']);
				mark+=parseFloat(d[index]['one_m2']);
				mark+=parseFloat(d[index]['one_m3']);
				mark+=parseFloat(d[index]['one_m4']);
				mark+=parseFloat(d[index]['one_m5']);
				mark+=parseFloat(d[index]['one_m6']);
				mark+=parseFloat(d[index]['one_m7']);
				mark+=parseFloat(d[index]['one_m8']);
				mark+=parseFloat(d[index]['one_m9']);
				mark+=parseFloat(d[index]['one_m10']);
				mark+=parseFloat(d[index]['11a']);
				mark+=parseFloat(d[index]['11b']);
				mark+=parseFloat(d[index]['12a']);
				mark+=parseFloat(d[index]['12b']);
				mark+=parseFloat(d[index]['13a']);
				mark+=parseFloat(d[index]['13b']);
				mark+=parseFloat(d[index]['14a']);
				mark+=parseFloat(d[index]['14b']);
				mark+=parseFloat(d[index]['15a']);
				mark+=parseFloat(d[index]['15b']);
				mark+=parseFloat(d[index]['16a']);
				mark+=parseFloat(d[index]['16b']);
				mark+=parseFloat(d[index]['17a']);
				mark+=parseFloat(d[index]['17b']);
				mark+=parseFloat(d[index]['18a']);
				mark+=parseFloat(d[index]['18b']);
				mark+=parseFloat(d[index]['19a']);
				mark+=parseFloat(d[index]['19b']);
				mark+=parseFloat(d[index]['20a']);
				mark+=parseFloat(d[index]['20b']);
			}
			return parseFloat(mark);
		}
		catch (error) { }
		
	}

	getScoredTotal(regno, type) {
		try {
			var mark = 0;
			var parta = 0
			var d = this.state.studentMark.filter(function (obj) {
				if (obj.registerno == regno) {
					parta = parseFloat(obj.parta / 5).toFixed(2);
					if (type == 'co1') {
						mark += parseFloat(obj['one_m1']);
						mark += parseFloat(obj['one_m2']);
						mark += parseFloat(obj['11a']);
						mark += parseFloat(obj['11b']);
						mark += parseFloat(obj['16a']);
						mark += parseFloat(obj['16b']);
					}
					else if (type == 'co2') {
						mark += parseFloat(obj['one_m3']);
						mark += parseFloat(obj['one_m4']);
						mark += parseFloat(obj['12a']);
						mark += parseFloat(obj['12b']);
						mark += parseFloat(obj['17a']);
						mark += parseFloat(obj['17b']);
					}
					else if (type == 'co3') {
						mark += parseFloat(obj['one_m5']);
						mark += parseFloat(obj['one_m6']);
						mark += parseFloat(obj['13a']);
						mark += parseFloat(obj['13b']);
						mark += parseFloat(obj['18a']);
						mark += parseFloat(obj['18b']);
					}
					else if (type == 'co4') {
						mark += parseFloat(obj['one_m7']);
						mark += parseFloat(obj['one_m8']);
						mark += parseFloat(obj['14a']);
						mark += parseFloat(obj['14b']);
						mark += parseFloat(obj['19a']);
						mark += parseFloat(obj['19b']);
					}
					else if (type == 'co5') {
						mark += parseFloat(obj['one_m9']);
						mark += parseFloat(obj['one_m10']);
						mark += parseFloat(obj['15a']);
						mark += parseFloat(obj['15b']);
						mark += parseFloat(obj['20a']);
						mark += parseFloat(obj['20b']);
					}
				};
			});
			return parseFloat(mark).toFixed(2);
		}
		catch (error) { }
	}

	getPsoReport(avg1, avg2, avg3, avg4, avg5) {

		var d = [];

		for (var i = 0; i < 5; i++) {
			d.push(<tr>
				<td style={tdStyle} align="center">Co {i + 1}</td>
				<td style={tdStyle} align="center">{this.getPsoAvgResult(avg1, avg2, avg3, avg4, avg5, i + 1, '1')}</td>
				<td style={tdStyle} align="center">{this.getPsoAvgResult(avg1, avg2, avg3, avg4, avg5, i + 1, '2')}</td>
				<td style={tdStyle} align="center">{this.getPsoAvgResult(avg1, avg2, avg3, avg4, avg5, i + 1, '3')}</td>
				<td style={tdStyle} align="center">{this.getPsoAvgResult(avg1, avg2, avg3, avg4, avg5, i + 1, '4')}</td>
				<td style={tdStyle} align="center">{this.getPsoAvgResult(avg1, avg2, avg3, avg4, avg5, i + 1, '5')}</td>
				<td style={tdStyle} align="center">{this.getPsoAvgResult(avg1, avg2, avg3, avg4, avg5, i + 1, '6')}</td>
				<td style={tdStyle} align="center">{this.getPsoAvgResult(avg1, avg2, avg3, avg4, avg5, i + 1, '7')}</td>
			</tr>);
		}
		return d
	}

	getPsoAvgResult(v1, v2, v3, v4, v5, co, pso) {

		var sub = this.state.subjectList;
		var id = this.state.selectedSubject;
		var index = sub.findIndex(function (obj) {
			return (obj.id == id);
		});

		var subject = sub[index];
		var result = '';

		if (pso == '1') {
			if (co == '1') {
				var arr = subject['co1_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		else if (pso == '2') {
			if (co == '1') {
				var arr = subject['co1_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '3') {
			if (co == '1') {
				var arr = subject['co1_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '4') {
			if (co == '1') {
				var arr = subject['co1_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '5') {
			if (co == '1') {
				var arr = subject['co1_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '6') {
			if (co == '1') {
				var arr = subject['co1_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '7') {
			if (co == '1') {
				var arr = subject['co1_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			if (co == '4') {
				var arr = subject['co4_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			if (co == '5') {
				var arr = subject['co5_pso'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		return result;
	}

	getPoReport(avg1, avg2, avg3, avg4, avg5) {
		var d = [];

		for (var i = 0; i < 5; i++) {
			d.push(<tr>
				<td style={tdStyle} align="center">Co {i + 1}</td>
				<td style={tdStyle} align="center">{this.getPoAvgResult(avg1, avg2, avg3, avg4, avg5, i + 1, '1')}</td>
				<td style={tdStyle} align="center">{this.getPoAvgResult(avg1, avg2, avg3, avg4, avg5, i + 1, '2')}</td>
				<td style={tdStyle} align="center">{this.getPoAvgResult(avg1, avg2, avg3, avg4, avg5, i + 1, '3')}</td>
				<td style={tdStyle} align="center">{this.getPoAvgResult(avg1, avg2, avg3, avg4, avg5, i + 1, '4')}</td>
				<td style={tdStyle} align="center">{this.getPoAvgResult(avg1, avg2, avg3, avg4, avg5, i + 1, '5')}</td>
				<td style={tdStyle} align="center">{this.getPoAvgResult(avg1, avg2, avg3, avg4, avg5, i + 1, '6')}</td>
				<td style={tdStyle} align="center">{this.getPoAvgResult(avg1, avg2, avg3, avg4, avg5, i + 1, '7')}</td>
			</tr>);
		}
		return d
	}

	getPoAvgResult(v1, v2, v3, v4, v5, co, pso) {

		var sub = this.state.subjectList;
		var id = this.state.selectedSubject;
		var index = sub.findIndex(function (obj) {
			return (obj.id == id);
		});

		var subject = sub[index];
		var result = '';

		if (pso == '1') {
			if (co == '1') {
				var arr = subject['co1_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 1) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		else if (pso == '2') {
			if (co == '1') {
				var arr = subject['co1_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 2) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '3') {
			if (co == '1') {
				var arr = subject['co1_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 3) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '4') {
			if (co == '1') {
				var arr = subject['co1_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 4) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '5') {
			if (co == '1') {
				var arr = subject['co1_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 5) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '6') {
			if (co == '1') {
				var arr = subject['co1_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '4') {
				var arr = subject['co4_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '5') {
				var arr = subject['co5_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 6) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		if (pso == '7') {
			if (co == '1') {
				var arr = subject['co1_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v1 >= '3') {
								result = 'A'
							}
							else if (v1 >= '2') {
								result = 'M'
							}
							else if (v1 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '2') {
				var arr = subject['co2_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v2 >= '3') {
								result = 'A'
							}
							else if (v2 >= '2') {
								result = 'M'
							}
							else if (v2 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			else if (co == '3') {
				var arr = subject['co3_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v3 >= '3') {
								result = 'A'
							}
							else if (v3 >= '2') {
								result = 'M'
							}
							else if (v3 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			if (co == '4') {
				var arr = subject['co4_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v4 >= '3') {
								result = 'A'
							}
							else if (v4 >= '2') {
								result = 'M'
							}
							else if (v4 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
			if (co == '5') {
				var arr = subject['co5_po'].split(",");
				if (arr != '') {
					arr.map((item, i) => {
						if (item == 7) {
							if (v5 >= '3') {
								result = 'A'
							}
							else if (v5 >= '2') {
								result = 'M'
							}
							else if (v5 < '2') {
								result = 'N'
							}
						}
					});
				}
			}
		}
		return result;
	}

	getSubject(id, field) {
		var d = this.state.subjectList;

		var index = d.findIndex(function (obj) {
			return (obj.id == id);
		});
		if (index > -1) {
			return d[index][field];
		}
	}

	calculateAvg(type) {
		var d = this.state.average;
		var internal = 0;
		var external = 0;
		d.map((item, i) => {
			if (type == 'co1') {
				internal += parseFloat(item.internal_co1_avg / 0.25);
				external += parseFloat(item.external_co1_avg / 0.75);
			}
			else if (type == 'co2') {
				internal += parseFloat(item.internal_co2_avg / 0.25);
				external += parseFloat(item.external_co2_avg / 0.75);
			}
			else if (type == 'co3') {
				internal += parseFloat(item.internal_co3_avg / 0.25);
				external += parseFloat(item.external_co3_avg / 0.75);
			}
			else if (type == 'co4') {
				internal += parseFloat(item.internal_co4_avg / 0.25);
				external += parseFloat(item.external_co4_avg / 0.75);
			}
			else if (type == 'co5') {
				internal += parseFloat(item.internal_co5_avg / 0.25);
				external += parseFloat(item.external_co5_avg / 0.75);
			}
		});
		return parseFloat((internal + external) / 100).toFixed(2);
	}

	render() {

		let deptName = this.state.courseList.length > 0 ? this.state.courseList[0]['departmentname'] : '';
		let deptId = this.state.courseList.length > 0 ? this.state.courseList[0]['department'] : '';

		let semesterList = [];
		if (this.state.selectedDegree == 'UG') {
			semesterList = [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' },
				{ label: '3', value: '3' },
				{ label: '4', value: '4' },
				{ label: '5', value: '5' },
				{ label: '6', value: '6' }
			]
		}
		else if (this.state.selectedDegree == 'PG') {
			semesterList = [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' },
				{ label: '3', value: '3' },
				{ label: '4', value: '4' }
			]
		}
		else if (this.state.selectedDegree == 'Mphil') {
			semesterList = [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' }
			]
		}

		let total = 0;
		let avg1 = 0;
		let avg2 = 0;
		let avg3 = 0;
		let avg4 = 0;
		let avg5 = 0;

		var max = 0;
		var data = [4, 3, 2, 1, 0];

		data.map(d => {
			max = Math.max(max, d)
		})

		var colors = [
			'#008FFB',
			'#00E396',
			'#FEB019',
			'#FF4560',
			'#775DD0'
		]
		
		var avgcolors = [
			'#AB47BC',
			'#EC407A',
			'#FFEE58',
			'#66BB6A',
			'#8D6E63'
		]

		return (
			<div>

				<LineLoader loading={this.state.showLoader} />

				<Menu />

				<div className="pt-50" >

					<form id="frm_add_external_mark" action="" method="post" onSubmit={this.handleSubmit}>
						<input type="hidden" name="i__userId" value={getls('user', 'id')} />
						<input type="hidden" name="i__deptId" id="i__deptId" value={deptId} />
						<input type="hidden" name="i__degree" id="i__degree" value={this.state.selectedDegree} />
						<input type="hidden" name="studentMark" value={JSON.stringify(this.state.studentMark)} />
						<Row>
							<Col md={12} >
								<Row>
									<Col md={1} ><Label>Department</Label></Col>
									<Col md={2}>
										<Input type="text" size="sm" name="i__deptName" value={deptName} required />
									</Col>
									<Col md={2} >
										<Input type="select" name="i__course" id="i__course" size="sm" required onChange={this.handleDegreeChange} >
											<option value=""> -Course- </option>
											{this.state.courseList.map((item, i) => {
												return <option key={i} value={item.id} data-type={item.type} data-name={item.coursename}> {item.coursename} ({item.coursetype == 'regular' ? 'R' : 'SF'})</option>;
											})
											}
										</Input>
									</Col>
									<Col md={2}>
										<Input type="select" name="i__subject" id="i__subject" size="sm" required onChange={(e) => { this.setState({ selectedSubject: e.target.value }) }} >
											<option value=""> -Subject- </option>
											{this.state.subjectList.map((item, i) => {
												return <option key={i} value={item.id} > {item.subcode} - {capitalizeFirst(item.subname)} </option>;
											})}
										</Input>
									</Col>
									<Col md={2}>
										<Input type="select" name="i__semester" id="i__semester" size="sm" required>
											<option value=""> -Semester- </option>
											{semesterList.map((item, i) => {
												return <option key={i} value={item.value} > {item.label} </option>;
											})}
										</Input>
									</Col>
									<Col md={2} className="pl-0" >
										<Input type="select" name="i__batch" size="sm" id="i__batch" onChange={this.handleYearChange} required >
											<option value=""> -Year- </option>
											{this.state.yearsList.map((item, i) => {
												return <option key={i} value={item.startyear} > {item.startyear} </option>;
											})
											}
										</Input>
									</Col>
									<Col md={1} >
										<div className="text-right" >
											<Button type="button" size="sm" color="info" onClick={this.handleViewBtnClick} disabled={this.state.viewBtnText != 'View'} >
												{this.state.viewBtnText != 'View' ? <i className="ion-loading-a"></i> : null}
												&nbsp;{this.state.viewBtnText}
											</Button>
											<button id="printbtn" type="button" style={{ position: 'fixed', bottom: '50px', right: '33px', zIndex: '999999' }} className="btn btn-warning btn-sm" onClick={this.printChart.bind(this)}>
												<i className="ion-printer"></i>
											</button>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>

						{Object.keys(this.state.studentsList).length > 0 ?
							<React.Fragment>
								<Row className="mt-10" >
									<Col md={12} >
										<Card>
											<CardBody>
												<Row>
													<Col md={2} >
														<Label>External Mark Entry</Label>
													</Col>
												</Row>
												<hr className="my-0" />
											</CardBody>
											<CardBody>
												<Table responsive className="tbl" style={{ fontSize: '11px' }}>
													<tbody>
														<tr style={{ background: '#00adff' }} >
															<td width="30" rowSpan="2" >S.No</td>
															<td rowSpan="2" >Reg.No</td>
															<td rowSpan="2" >Student Name</td>
															<td colSpan="10" align="center">Part A</td>
															<td colSpan="2" align="center">11</td>
															<td colSpan="2" align="center">12</td>
															<td colSpan="2" align="center">13</td>
															<td colSpan="2" align="center">14</td>
															<td colSpan="2" align="center">15</td>
															<td colSpan="2" align="center">16</td>
															<td colSpan="2" align="center">17</td>
															<td colSpan="2" align="center">18</td>
															<td colSpan="2" align="center">19</td>
															<td colSpan="2" align="center">20</td>
															<td rowSpan="2" align="center">Total</td>
															<td rowSpan="2" align="center">Co 1</td>
															<td rowSpan="2" align="center">Co 2</td>
															<td rowSpan="2" align="center">Co 3</td>
															<td rowSpan="2" align="center">Co 4</td>
															<td rowSpan="2" align="center">Co 5</td>
														</tr>
														<tr style={{ background: 'rgb(73, 255, 146)' }}>
															<td align="center" >1</td>
															<td align="center" >2</td>
															<td align="center" >3</td>
															<td align="center" >4</td>
															<td align="center" >5</td>
															<td align="center" >6</td>
															<td align="center" >7</td>
															<td align="center" >8</td>
															<td align="center" >9</td>
															<td align="center" >10</td>
															<td align="center">A</td>
															<td align="center">B</td>
															<td align="center">A</td>
															<td align="center">B</td>
															<td align="center">A</td>
															<td align="center">B</td>
															<td align="center">A</td>
															<td align="center">B</td>
															<td align="center">A</td>
															<td align="center">B</td>
															<td align="center">A</td>
															<td align="center">B</td>
															<td align="center">A</td>
															<td align="center">B</td>
															<td align="center">A</td>
															<td align="center">B</td>
															<td align="center">A</td>
															<td align="center">B</td>
															<td align="center">A</td>
															<td align="center">B</td>
														</tr>
														{this.state.studentsList.map((item, i) => {
															
															avg1 += parseFloat((this.getScoredTotal(item.registerno, 'co1') / 15) * 100);
															avg2 += parseFloat((this.getScoredTotal(item.registerno, 'co2') / 15) * 100);
															avg3 += parseFloat((this.getScoredTotal(item.registerno, 'co3') / 15) * 100);
															avg4 += parseFloat((this.getScoredTotal(item.registerno, 'co4') / 15) * 100);
															avg5 += parseFloat((this.getScoredTotal(item.registerno, 'co5') / 15) * 100);
															this.sstate = {
																options: {
																	chart: {
																		id: 'apexchart-example'
																	},
																	colors: colors,
																	plotOptions: {
																		bar: {
																			columnWidth: '45%',
																			distributed: true
																		}
																	},
																	dataLabels: {
																		enabled: false
																	},
																	xaxis: {
																		categories: ['Co1', 'Co2', 'Co3', 'Co4', 'Co5'],
																		labels: {
																			style: {
																				colors: colors,
																				fontSize: '14px'
																			}
																		}
																	},
																	yaxis: {
																		allowDecimals: false,
																		tickAmount: 5,
																		max: max + 1
																	}
																},
																series: [{
																	name: 'average',
																	data: [parseFloat((avg1 / this.state.studentsList.length) / 20).toFixed(2), parseFloat((avg2 / this.state.studentsList.length) / 20).toFixed(2), parseFloat((avg3 / this.state.studentsList.length) / 20).toFixed(2), parseFloat((avg4 / this.state.studentsList.length) / 20).toFixed(2), parseFloat((avg5 / this.state.studentsList.length) / 20).toFixed(2)]
																}],
																avgoptions: {
																	chart: {
																		type: 'bar',
																		height: 350
																	},
																	colors: avgcolors,
																	plotOptions: {
																		bar: {
																			columnWidth: '45%',
																			distributed: true,
																		}
																	},
																	dataLabels: {
																		enabled: false
																	},
																	xaxis: {
																		categories: ['Co1', 'Co2', 'Co3', 'Co4', 'Co5'],
																	},
																	yaxis: {
																		allowDecimals: false,
																		tickAmount: 5,
																		max: max + 1,
																		labels: {
																			style: {
																				colors: avgcolors,
																				fontSize: '14px'
																			}
																		}
																	}
																},
																avgseries: [{
																	name: 'average',
																	data: [this.calculateAvg('co1'), this.calculateAvg('co2'), this.calculateAvg('co3'), this.calculateAvg('co4'), this.calculateAvg('co5')]
																}]
															}
															return <tr key={i}>
																<td>{i + 1}</td>
																<td>{item.registerno}</td>
																<td>{item.name}</td>
																<td><input type="hidden" name="avg1" value={avg1} />
																	<Input type="number" style={inputStyle} size="sm" max="1" onChange={this.handleMarkChange.bind(this, item.registerno, 'one_m1')} value={this.getScoredMark(item.registerno, 'one_m1')} />
																</td>
																<td>
																	<Input type="number" style={inputStyle} size="sm" max="1" onChange={this.handleMarkChange.bind(this, item.registerno, 'one_m2')} value={this.getScoredMark(item.registerno, 'one_m2')} />
																</td>
																<td>
																	<Input type="number" style={inputStyle} size="sm" max="1" onChange={this.handleMarkChange.bind(this, item.registerno, 'one_m3')} value={this.getScoredMark(item.registerno, 'one_m3')} />
																</td>
																<td>
																	<Input type="number" style={inputStyle} size="sm" max="1" onChange={this.handleMarkChange.bind(this, item.registerno, 'one_m4')} value={this.getScoredMark(item.registerno, 'one_m4')} />
																</td>
																<td>
																	<Input type="number" style={inputStyle} size="sm" max="1" onChange={this.handleMarkChange.bind(this, item.registerno, 'one_m5')} value={this.getScoredMark(item.registerno, 'one_m5')} />
																</td>
																<td>
																	<Input type="number" style={inputStyle} size="sm" max="1" onChange={this.handleMarkChange.bind(this, item.registerno, 'one_m6')} value={this.getScoredMark(item.registerno, 'one_m6')} />
																</td>
																<td>
																	<Input type="number" style={inputStyle} size="sm" max="1" onChange={this.handleMarkChange.bind(this, item.registerno, 'one_m7')} value={this.getScoredMark(item.registerno, 'one_m7')} />
																</td>
																<td>
																	<Input type="number" style={inputStyle} size="sm" max="1" onChange={this.handleMarkChange.bind(this, item.registerno, 'one_m8')} value={this.getScoredMark(item.registerno, 'one_m8')} />
																</td>
																<td>
																	<Input type="number" style={inputStyle} size="sm" max="1" onChange={this.handleMarkChange.bind(this, item.registerno, 'one_m9')} value={this.getScoredMark(item.registerno, 'one_m9')} />
																</td>
																<td>
																	<Input type="number" style={inputStyle} size="sm" max="1" onChange={this.handleMarkChange.bind(this, item.registerno, 'one_m10')} value={this.getScoredMark(item.registerno, 'one_m10')} />
																</td>
																<td><Input type="number" style={inputStyle} size="sm" max="5"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '11a')}
																	value={this.getScoredMark(item.registerno, '11a')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="5"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '11b')}
																	value={this.getScoredMark(item.registerno, '11b')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="5"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '12a')}
																	value={this.getScoredMark(item.registerno, '12a')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="5"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '12b')}
																	value={this.getScoredMark(item.registerno, '12b')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="5"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '13a')}
																	value={this.getScoredMark(item.registerno, '13a')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="5"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '13b')}
																	value={this.getScoredMark(item.registerno, '13b')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="5"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '14a')}
																	value={this.getScoredMark(item.registerno, '14a')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="5"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '14b')}
																	value={this.getScoredMark(item.registerno, '14b')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="5"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '15a')}
																	value={this.getScoredMark(item.registerno, '15a')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="5"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '15b')}
																	value={this.getScoredMark(item.registerno, '15b')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="8"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '16a')}
																	value={this.getScoredMark(item.registerno, '16a')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="8"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '16b')}
																	value={this.getScoredMark(item.registerno, '16b')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="8"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '17a')}
																	value={this.getScoredMark(item.registerno, '17a')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="8"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '17b')}
																	value={this.getScoredMark(item.registerno, '17b')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="8"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '18a')}
																	value={this.getScoredMark(item.registerno, '18a')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="8"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '18b')}
																	value={this.getScoredMark(item.registerno, '18b')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="8"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '19a')}
																	value={this.getScoredMark(item.registerno, '19a')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="8"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '19b')}
																	value={this.getScoredMark(item.registerno, '19b')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="8"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '20a')}
																	value={this.getScoredMark(item.registerno, '20a')} /></td>
																<td><Input type="number" style={inputStyle} size="sm" max="8"
																	onChange={this.handleMarkChange.bind(this, item.registerno, '20b')}
																	value={this.getScoredMark(item.registerno, '20b')} /></td>
																<td>
																	<input type="hidden" name="total[]" value={this.getTotalMark(item.registerno)} />
																{this.getTotalMark(item.registerno) > 0 ? this.getTotalMark(item.registerno) : '0.00'}</td>
																<td>{this.getScoredTotal(item.registerno, 'co1')}</td>
																<td>{this.getScoredTotal(item.registerno, 'co2')}</td>
																<td>{this.getScoredTotal(item.registerno, 'co3')}</td>
																<td>{this.getScoredTotal(item.registerno, 'co4')}</td>
																<td>{this.getScoredTotal(item.registerno, 'co5')}</td>
															</tr>
														})}
														<tr>
															<td colSpan="34" align="right">Total
																<input type="hidden" name="co1_avg" value={parseFloat(avg1 / this.state.studentsList.length).toFixed(2)} />
																<input type="hidden" name="co2_avg" value={parseFloat(avg2 / this.state.studentsList.length).toFixed(2)} />
																<input type="hidden" name="co3_avg" value={parseFloat(avg3 / this.state.studentsList.length).toFixed(2)} />
																<input type="hidden" name="co4_avg" value={parseFloat(avg4 / this.state.studentsList.length).toFixed(2)} />
																<input type="hidden" name="co5_avg" value={parseFloat(avg5 / this.state.studentsList.length).toFixed(2)} />
															</td>
															<td>{parseFloat(avg1 / this.state.studentsList.length).toFixed(2)}</td>
															<td>{parseFloat(avg2 / this.state.studentsList.length).toFixed(2)}</td>
															<td>{parseFloat(avg3 / this.state.studentsList.length).toFixed(2)}</td>
															<td>{parseFloat(avg4 / this.state.studentsList.length).toFixed(2)}</td>
															<td>{parseFloat(avg5 / this.state.studentsList.length).toFixed(2)}</td>
														</tr>
													</tbody>
												</Table>
											</CardBody>
											<CardBody>
												<Row>
													<Col md="11"></Col>
													<Col md={1} >
														<Button type="submit" color="primary" disabled={this.state.saveText != 'Save' ? true : false}>
															{this.state.saveText != 'Save' ? <span className="ion-loading-a" ></span> : ''} {this.state.saveText}
														</Button>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</Row>
								<Row id="print_chart">
									<Col md={6}>
										<div className="print_title" style={{ display: 'none' }}>
											<center>
												<b style={{ fontSize: '20px' }}>{ClientName}</b><br />
												Department : <b>{this.state.cname}-{deptName}</b><br />
												Subject : <b>{this.getSubject(this.state.selectedSubject, 'subcode')} - {this.getSubject(this.state.selectedSubject, 'subname')}</b><br />
												Semester : <b>{$("#i__semester").val()}</b>&emsp;&emsp;Batch : <b>{$("#i__batch").val()}</b>
											</center>
										</div>
										<h3>PSO Report</h3>
										<table width="100%" style={{ borderCollapse: 'collapse' }}>
											<thead>
												<tr>
													<th style={tdStyle}>CO</th>
													<th style={tdStyle}>PSO 1</th>
													<th style={tdStyle}>PSO 2</th>
													<th style={tdStyle}>PSO 3</th>
													<th style={tdStyle}>PSO 4</th>
													<th style={tdStyle}>PSO 5</th>
													<th style={tdStyle}>PSO 6</th>
													<th style={tdStyle}>PSO 7</th>
												</tr>
											</thead>
											<tbody>
												{this.getPsoReport(avg1, avg2, avg3, avg4, avg5)}
											</tbody>
										</table>
									</Col>
									<Col md={6}>
										<h3>PO Report</h3>
										<table width="100%" style={{ borderCollapse: 'collapse' }}>
											<thead>
												<tr>
													<th style={tdStyle}>CO</th>
													<th style={tdStyle}>PO 1</th>
													<th style={tdStyle}>PO 2</th>
													<th style={tdStyle}>PO 3</th>
													<th style={tdStyle}>PO 4</th>
													<th style={tdStyle}>PO 5</th>
													<th style={tdStyle}>PO 6</th>
													<th style={tdStyle}>PO 7</th>
												</tr>
											</thead>
											<tbody>
												{this.getPoReport(avg1, avg2, avg3, avg4, avg5)}
											</tbody>
										</table>
									</Col><br /><br />
									<Col md={6} style={{marginTop : '10px'}}>
										<h3>CO-PSO Chart</h3>
										<Chart options={this.sstate.options} series={this.sstate.series} type="bar" width={500} height={300} />
									</Col>
									<Col md={6} style={{marginTop : '10px'}}>
										<h3>CO-PO Chart</h3>
										<Chart options={this.sstate.avgoptions} series={this.sstate.avgseries} type="bar" width={500} height={300} />
									</Col>
									<iframe name="print_frame" id="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
								</Row>
							</React.Fragment> : null}

					</form>

				</div>

			</div>
		);
	}

}

export default ExternalMark;
